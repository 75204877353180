import React, { useEffect } from 'react' 
import { useLocation  } from 'react-router-dom' 
import { MenuList } from '../jsx/layouts/nav/Menu' 
import { useActive } from '../context/ActiveContext'
const ActiveSection = () => {
    const location = useLocation()
    const { state, handleMenuActive } = useActive()

    function activeLink () {
        let index_active_link = MenuList.findIndex(menulink=> (menulink.to===location.pathname)) 
        if(index_active_link === -1) {
          if(location.pathname === '/ordercard') {
            return 'My Card' 
          } else if(location.pathname === '/referredusers') {
            return 'Referral'  
          } else if(location.pathname === '/getstarted') {
            return 'Dashboard' 
          } else {
            return '' 
          }
        } else {
          return MenuList[index_active_link].title
        }
      } 

    useEffect(()=>{
        if(state.active === activeLink()) {
        } else {
            handleMenuActive(activeLink())
            handleMenuActive(activeLink())
        }
    }, [location.pathname]) 
}

export default ActiveSection

