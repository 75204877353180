import React from 'react'
import './CSS/referralchunkdata.css' 
const ReferralChunkData = ({imagesrc, alt, title, description, value}) => {
  return (
    <div className='referralChunkDataContainer'>
        <div className='referralChunkDataFirstRow'>
            <img src={imagesrc} alt={alt} draggable='false' /> 
            <div className='referralChunkDatatextualDescription'>
                <span>{title}</span>
                <span>{description}</span>
            </div>
        </div>
        <div className='referralChunkDataSecondRow'>{value}</div>
    </div>
  )
}

export default ReferralChunkData