import React from 'react'
import './CSS/resetpasswordemail.css' 
import { Link,useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup' 
import TextInput from '../components/TextInput'
import { generateOTPForgetPassword } from '../security/AuthService'                                  
import SEO from '../components/SEO'

const ResetPasswortEmail = () => {
    const navigate = useNavigate()
    
  return (
    <>
      <SEO title={'PayTaps - Your Crypto Payment Solution'} 
          description={'PayTaps Crypto Visa Card: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          ogTitle={'PayTaps Crypto Visa Card – Spend Crypto With A Simple Tap'} 
          ogDescription={'PayTaps Crypto Visa Card: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          ogUrl={'https://paytaps.com'}  
          twitterTitle={'PayTaps Crypto Visa Card – Spend Crypto With A Simple Tap'} 
          twitterDescription={'PayTaps Crypto Visa Card: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
          canonicalLink={'https://paytaps.com'}    
      /> 
      <section className='resetPasswordEmailContainer'>
        <div className='resetPasswordEmailWrapper'>
          <div className='resetPasswordEmailContent resetPasswordEmailContainerPaddingAdjustement'>
              <Link to='/'>
                  <img src='/images/registration/Group 1.svg' alt='paytabs logo' draggable='false' className='paytapsLogo' />
              </Link>
              <div className='resetPassword'>Reset Password </div>
              <div className='resetPasswordEnterCodeSentEmail'>Enter your Email an OTP code will be sent to you!</div>
              <Formik
                initialValues={{
                    email: ''
                }}

                validationSchema={YUP.object({
                    email: YUP.string().required('Required!').email('Invalid Email!'),
                })}

                onSubmit={ async (values, {setSubmitting, resetForm})=>{
                  try {

                    let result = await generateOTPForgetPassword({email: values.email})  
                    const reponse = await result.json()
                    
                    if(result.ok && reponse.message === 'successfully') {
                      navigate('/resetpasswordverification', { state: { email: values.email, loginType: reponse['data']['loginType'] }}) 
                    }
                    setSubmitting(false)
                    resetForm()
                  } catch(err) {

                  }
                }}
              >
                    
                {(formik) => (
                  <Form className='form' onSubmit={formik.handleSubmit}>
                    <TextInput
                      inputtype='email'
                      name='email'
                      id='email'
                      label='Email'
                    />
                    <button
                      type='submit'
                      id='resetpassword' 
                      disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
                    >
                      Reset
                    </button>
                  </Form>
                )}      
              </Formik>
                          

              <div className='already-have-account text-center'>
                  Remember It? 
                  <Link to='/login' style={{
                      fontWeight: '700',
                      color: '#0E0E0E',
                      textDecoration: 'underline'
                  }}>  Sign in here </Link>
              </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ResetPasswortEmail 