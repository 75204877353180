import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../components/Button' 

const ErrorLinkingAuth = ({show, close, error}) => {

  return (
    <Modal show={show} className='loader-error' backdrop='static' keyboard='false'>  
            <Modal.Body className='center'>
                { <div className='errorMessageContainer'> 
                                <figure>
                                    <img src='/images/dashboard/2fa/Reset password-rafiki.svg' width='300' /> 
                                </figure>
                                <div>{error}</div>
                                <Button styles= 'try-again-btn' click={close}>OK</Button> 
                            </div>
                }   
            </Modal.Body>
    </Modal> 
  )
}

export default ErrorLinkingAuth 


