import React, {useState, useEffect, useCallback} from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './lpheader.css'
import { useActive } from '../../context/ActiveContext';
import { useNavigate } from 'react-router-dom'; 
const LpHeader = () => {
  const [previousScrollY, setPreviousScrollY] = useState(window.screenY)
  const [currentScrollY, setCurrentScrollY] = useState(window.screenY)
  const [isLogged, setIsLogged] = useState(false)  
  const { handleMenuActive } = useActive()  
  const navigate = useNavigate() 
  const scrollValueHandler = useCallback(function scrollValueHandler () {
    window.addEventListener('scroll', ()=>{
      setCurrentScrollY(window.scrollY)
    })
  }, [])

  useEffect(()=>{
    if(localStorage.getItem('u_t_h') && localStorage.getItem('u_t_p') && localStorage.getItem('u_t_s')) {
      setIsLogged(true) 
    }
  }, [isLogged]) 

  useEffect(()=>{
    scrollValueHandler()
    if(currentScrollY < previousScrollY) {
      document.getElementById('header').classList.add('fixed-header')
      document.querySelector('.paytabs-master-card-title').classList.remove('mt-55')
      document.querySelector('.paytabs-master-card-title').classList.add('mt-165')
    }
    else if(currentScrollY > previousScrollY) {
      document.getElementById('header').classList.remove('fixed-header')
      document.getElementById('header').style.top = '-50%'
      document.querySelector('.paytabs-master-card-title').classList.remove('mt-165')
      document.querySelector('.paytabs-master-card-title').classList.add('mt-55')
    }
    setPreviousScrollY(currentScrollY)
  }, [currentScrollY, previousScrollY, scrollValueHandler])

  const scrollHandler = useCallback(function scrollHandler(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "start"});
    }, [])

    const redirectToDashboard = useCallback(function () { 
      navigate('/dashboard')  
      handleMenuActive('Dashboard')  
      }
    , []) 

  return (
    <Navbar expand="xl" className="bg-header fixed-header" id='header'>
      <Container className='align-items-center'>
        <Navbar.Brand href="/">
          <img src='images/landingpage/header/Layer 2.svg' alt='paytabs logo' className='brand-logo' />   
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('card-features')}> CARD FEATURES </Nav.Link>
            <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('product-overview')}>OVERVIEW</Nav.Link>
            <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('howitworks')}>HOW IT WORKS</Nav.Link>
            <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('benefits')}>BENEFITS</Nav.Link>
            <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('securetrusted')}>SECURITY</Nav.Link>
            
            {/* If user is not logged in */}
            {!isLogged && <>
                            <Link to='/login' className='apply-btn'>LOG IN</Link> 
                            <Link to='/register' className='apply-btn'>SIGN UP</Link> 
                          </>
            } 
            {/* If user is logged in */}
            {isLogged &&  <>
                            <span className='apply-btn' style={{cursor: 'pointer'}} onClick={redirectToDashboard}>Dashboard</span>       
                          </>
            } 
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default LpHeader

  