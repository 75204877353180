import React, { useState, useCallback, useRef } from 'react'
import './CSS/getstarted.css' 
import Button from '../components/Button'  
import UnsufficientBalance from '../Modals/UnsufficientBalance'

const GetStarted = () => {
  const refAllow = useRef(false)  
  const [unsufficientBalanceState, setUnsufficientBalanceState] = useState(false)
  const [clickDepositStatus, setClickDepositStatus] = useState(false) 
  const handleShowUnsBalance = useCallback(function() {
    setUnsufficientBalanceState(true)
    refAllow.current = true 
    setClickDepositStatus(true) 
  }, [])
  const handleCloseUnsBalance = useCallback(function() {
    setUnsufficientBalanceState(false)
    refAllow.current = false
    setClickDepositStatus(false) 
 
  }, [])
  const handleCloseUnsBalance1 = useCallback(function() {
    setUnsufficientBalanceState(false)  
  }, [])
  const handleShowUnsBalance1 = useCallback(function() {
    setUnsufficientBalanceState(true)   
  }, [])
  return (
    <>
      <div className='getStartedOuterContainer'>
          <div className='getStartedInnerContainer'>
              <video id='paytapsWelcomeDepositVideo' autoPlay loop muted disablePictureInPicture playsInline>                                                                                                                                                                                            
                  <source src="/videos/paytaps welcome gif deposit.mp4" type="video/mp4" />
              </video>
              <div className='getStartedContentWrapper'>
                  <div className='getStartedLabel'>Get Started</div>
                  <p className='getStartedDescription'>You need a minimum balance of 60 USDT to order your card. Top up now to complete your account!</p>  
                  <Button id='depositNow' click={handleShowUnsBalance}>Deposit Now</Button>   
              </div>
          </div> 
      </div>
      {clickDepositStatus && <UnsufficientBalance show={unsufficientBalanceState} handleClose={handleCloseUnsBalance} a={refAllow.current} handleclickDepositStatusOff={handleCloseUnsBalance}  handleCloseUnsBalance1={handleCloseUnsBalance1} handleShowUnsBalance1={handleShowUnsBalance1} /> }           
    </>
  )
}

export default GetStarted   
