import React, { useCallback } from 'react'
import './CSS/sharesuccess.css' 
import { Modal } from 'react-bootstrap' 
import Button from '../components/Button'

const ShareSuccess = ({show, fadeSuccess, isEmailALreadyExist, initializeEmailAlreadyExist}) => {

  const handleOK = useCallback( function () {
    if(isEmailALreadyExist) {
      initializeEmailAlreadyExist()
    } 
    return fadeSuccess()   
  }, [isEmailALreadyExist])  

  return (
    <Modal show={show} onHide={fadeSuccess} className='successfulShare' backdrop='static' keyboard='false'>
        <div>
            <figure>
                <img src='/images/dashboard/referral/email card.svg' alt='an open envelope in front of it a paper rocket with two cards  on top of it, a plant at the bottom of the envelope, 
                    and a blue shield at the top left of the envelope' draggable='false' /> 
            </figure>
            <div className='sure-to-continue-top-up'> {!isEmailALreadyExist ? 'Invitation was sent Successfully!' : 'Email Already Used'} </div>  
            <div> {!isEmailALreadyExist ? 'Your friend has received the invite. Thank you for sharing and helping us grow our community!' : 'This email has already been used. Please try a different email '}</div>  
        </div>
        
        <Button styles= 'try-again-btn okSuccessTopup' click={handleOK}>Got it</Button>                
        
    </Modal> 
  )
}

export default ShareSuccess 