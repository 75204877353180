import React, { useState, useRef, useCallback, useEffect } from 'react'
import './CSS/dashboard.css'
import Button from './../components/Button'
import WalletDeposit from '../Modals/WalletDeposit'
import { useLoaderData, useNavigate } from 'react-router-dom' 
import UnsufficientBalance from '../Modals/UnsufficientBalance';
import { useStateCountDown } from '../context/EnableCountDown'   
import { userBalance, cardInfo, getCardBalance, convertToUSDT } from '../security/AuthService'    
import Loader from '../jsx/pages/Loader/Loader' 
import { useActive } from '../context/ActiveContext'  
import Topup from '../Modals/Topup' 
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up'  
import TopupSuccess from '../Modals/TopupSuccess' 
import { useReferral } from '../context/ReferralContext'
import OrderCardNow from '../components/OrderCardNow'  
import Illustration from '../components/Illustration'
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 

const Dashboard = () => {
  const { showReferral, handleShowReferral } = useReferral() 
  const {stateCountDown, setStateCountDown} = useStateCountDown()
  const { handleMenuActive } = useActive() 
  const [show, setShow] = useState(false)
  const result = useLoaderData() 
  const [unsufficientBalanceState, setUnsufficientBalanceState] = useState(false)
  const refAllow = useRef(false) 
  const [clickDepositStatus, setClickDepositStatus] = useState(false) 
  const navigate = useNavigate() 
  const [balanceUSDT, setBalanceUSDT] = useState('') 
  const [dashboardataLoadingState, setDashboardataLoadingState] = useState(true)  
  const [creditLimit, setCreditLimit] = useState('0')    
  const [cardBalance, setCardBalance] = useState('0')
  const [showTopUp, setShowTopUp] = useState(false) 
  const [hasCard, setHasCard] = useState(false) 
  const [balanceUsd, setBalanceUsd] = useState(0)  
  const [showSuccess, setShowSuccess] = useState(false)                                                                                  
  const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)  

  const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
  const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);

  useEffect(()=>{
    HandleAllDeposits()
    if(typeof result === 'string') {
      refAllow.current = false
    } else if((typeof result === 'object')) {
      refAllow.current = false 
    }
  }, []) 
    
    const handleClose = () => {setShow(false); setStateCountDown(false)} 

    const handleShow = function() {
      if(typeof result === 'string') {
        navigate('/thankyoufordeposit')  
        return handleMenuActive('')        
      }

      if(result.normal_deposit) {
        setShow(true); 
        setStateCountDown(true)
      } else if(!result.normal_deposit) {
        handleShowUnsBalance() 
        refAllow.current = true 
      }

      setClickDepositStatus(true) 
    }
     
    function setRefFalse() {
      setStateCountDown(false)
    }

    function setRefTrue() { 
      setStateCountDown(true)
    }
    
    const handleShowUnsBalance = useCallback(function() {
      setUnsufficientBalanceState(true)
    }, [])

    const handleCloseUnsBalance = useCallback(function() {
      setUnsufficientBalanceState(false)
    }, [])

    const handleclickDepositStatusOff = useCallback(function() {
      setClickDepositStatus(false) 
    }, []) 

    const updateBalanceUSDT = useCallback(function (newBalance) {
      setBalanceUSDT(newBalance) 
    }, [])
    const updateBalanceUsd = useCallback(function (newBalance) {
      setBalanceUsd(newBalance) 
    }, [])    

    async function HandleAllDeposits() {
      
      try {
          setDashboardataLoadingState(true) 
            let result_fetch_balance_wallet = {
              api_call: await userBalance()
            }
            const renewB = async ()=>{
              return await userBalance()
            }
            // If fetch returns 401
            if(result_fetch_balance_wallet.api_call.status === 401) {
              if(await refreshTokenWithAPICallHandler(result_fetch_balance_wallet, renewB) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login')  
              }
            }
            const wallet_balance_usdt = await result_fetch_balance_wallet.api_call.json() 
            setBalanceUSDT(wallet_balance_usdt['data'][0]['balance'])   

            let convert = {
              api_call: await convertToUSDT({
                amount: wallet_balance_usdt['data'][0]['balance'] 
              })
            }
            const renewCTU = async ()=>{
              return await convertToUSDT({
                amount: wallet_balance_usdt['data'][0]['balance'] 
              })
            }
            // If fetch returns 401
            if(convert.api_call.status === 401) {
              if(await refreshTokenWithAPICallHandler(convert, renewCTU) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login')  
              }
            }
            const convert_result = await convert.api_call.json() 

            let card_fetch_info = {
              api_call: await cardInfo()
            }
            const renewCAI = async ()=>{
              return await cardInfo()
            }
            // If fetch returns 401
            if(card_fetch_info.api_call.status === 401) {
              if(await refreshTokenWithAPICallHandler(card_fetch_info, renewCAI) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login')  
              }
            }
            const result_card_info = await card_fetch_info.api_call.json() 

            if(result_fetch_balance_wallet.api_call.ok && card_fetch_info.api_call.ok && convert.api_call.ok) {     
              setBalanceUsd(convert_result['data']['result'])                                                                                

              if(result_card_info['data'].length > 0) {
                if(!showReferral) {
                  handleShowReferral() 
                }
                let card_balance = {
                  api_call: await getCardBalance()
                }
                const renewCAB = async ()=>{
                  return await getCardBalance()
                }
                // If fetch returns 401
                if(card_balance.api_call.status === 401) {
                  if(await refreshTokenWithAPICallHandler(card_balance, renewCAB) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                  }
                }
                if(card_balance.api_call.ok) {                   
                  const card_balance_result = await card_balance.api_call.json() 
                  setCardBalance(card_balance_result['data']['balance'])
                }
                setCreditLimit(result_card_info['data'][0]['creditLimit'])  
                setHasCard(true)   
              } else {
                setCreditLimit('0') 
                setHasCard(false)  
              }
                                        
              setDashboardataLoadingState(false) 
 
            } 
      } catch(err) {
      }
    }  
    
    const handleCloseTopUp = useCallback(function() {
      setShowTopUp(false) 
    }, [])

    const topupCardHandler = useCallback(function (e) {
      e.stopPropagation()
      if(result?.normal_deposit) {
        setShowTopUp(true) 
      }
    }) 

    const handleCloseTopupSuccess = useCallback(function () {
      setShowSuccess(false) 
    }, [])

    const handleShowTopupSuccess = useCallback(function () {
      setShowSuccess(true)
    }, []) 

    const orderCardHandler = useCallback(function (e) {
      e.stopPropagation() 
      handleMenuActive('') 
      navigate('/ordercard')
    }, [])  

    const redirectToCardHandler = useCallback(function () {
      if(result?.normal_deposit && hasCard) {
        handleMenuActive('My Card') 
      } else {
        handleMenuActive('')
      }
      navigate('/mycard') 
    })
      
return (
    <>
      {!dashboardataLoadingState && <div className='dashboard-container'>
          <section className='dashboard-overview-container'> 

              <figure className='available-balance paytapsCard'>
                <span className='mainWalletTitle'>Main wallet</span>
                <span className='available-balance-euro'>Available balance in USDT</span>
                <span className='available-balance-euro-amount'>{parseFloat(balanceUSDT).toFixed(2)}</span>      
                <Button 
                  imagesrc='/images/dashboard/dashboard/Background (2).svg' 
                  styles='deposit-btn' 
                  click={handleShow}
                >Deposit</Button> 
              </figure>  

              <figure className='paytapsCardBgWrapper' onClick={redirectToCardHandler}> 
                <img 
                  src='/images/dashboard/mycard/cards-new-visa1.png'
                  alt='A purple area with a linear gradient from lightest to darkest towards the top right, in the upper left corner a full Paytabs logo. At the bottom a large Paytabs text with low opacity shifted a little downwards and in the lower right corner a white VISA text'  
                  draggable='false' 
                  loading='eager' 
                />  
                <span className='available-balance-euro'>Available balance in USD</span>
                <span className='available-balance-euro-amount'>{parseFloat(cardBalance).toFixed(2)}</span> 
                {(result?.normal_deposit && hasCard) ? 
                  <Button imagesrc='/images/dashboard/dashboard/Background (3).svg' styles='topup-btn' click={topupCardHandler}>Top up</Button> :  
                  <Button imagesrc='/images/dashboard/dashboard/Icon.svg' styles='topup-btn w-147 h-35' click={orderCardHandler}>Order Card</Button>
                } 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
              </figure>
              
              <div className="card bgl-primary card-body overflow-hidden p-0 d-none d-md-flex rounded " style={{height: '100%', marginBottom: '0px'}}>
                <div className="p-0 text-center mt-3" style={{zIndex: '2'}}> 
                    <span className="text-black">Limit</span>
                    <h3 className="text-black fs-20-m18 mb-0 font-w600">${parseFloat(creditLimit).toFixed(1)}</h3>                         
                    <small>${localStorage.getItem('c_l') === '100000' ? '100K' : localStorage.getItem('c_l')}</small>  
                </div>
                <div className="mt-auto line-chart-demo" style={{position: 'absolute',background: '#1eaae780', inset: '0', height: `${parseFloat(localStorage.getItem('c_l')) > 0 ? ((parseFloat(creditLimit) * 100) /parseFloat(localStorage.getItem('c_l'))): 0}%`}}>                                                                                                           

                </div>									
              </div> 

          </section>
          {!result.has_card && <OrderCardNow />}
          {result.has_card && <Illustration />}    
      </div>}
      {(dashboardataLoadingState) && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}

      {(result?.normal_deposit && clickDepositStatus) && 
        <WalletDeposit 
          show={show} 
          handleClose={handleClose} 
          stateCountDown={stateCountDown} 
          setRefFalse={setRefFalse} 
          setRefTrue={setRefTrue} 
          handleclickDepositStatusOff={handleclickDepositStatusOff} 
        /> 
      }     
      {(!result?.normal_deposit && clickDepositStatus) && 
        <UnsufficientBalance 
          show={unsufficientBalanceState} 
          handleClose={handleCloseUnsBalance} 
          a={refAllow.current} 
          handleclickDepositStatusOff={handleclickDepositStatusOff} 
        />    
      }      
      <Topup 
        show={showTopUp} 
        handleClose={handleCloseTopUp} 
        handleShowInsufTopupBalance={handleShowInsufTopupBalance} 
        updateBalance={updateBalanceUSDT}
        updateBalanceUsd={updateBalanceUsd}   
        balanceUsd={balanceUsd} 
        balanceUSDT={balanceUSDT} 
        handleShowTopupSuccess={handleShowTopupSuccess} 
      />                                             
      <InsufficientBalance_top_up 
        show={showInsufficientTopupBalance} 
        handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} 
        handleShowInsufTopupBalance={handleShowInsufTopupBalance} 
      />                                                                         
      <TopupSuccess show={showSuccess} handleClose={handleCloseTopupSuccess} />       
    </> 
  )
}

export default Dashboard

