import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useActive } from '../../../context/ActiveContext'; 
const ResetPassword = () => {
    const navigate = useNavigate();
    const { handleMenuActive } = useActive()
    function goToResetPassword () {
        navigate('/loggedresetpassword') 
        handleMenuActive('') 
    }
    return(
        <>
            <button  className="dropdown-item ai-icon" onClick={goToResetPassword}>  
                <img src='/images/dashboard/header/tabler_lock.svg' alt='green lock' loading='eager' draggable='false' style={{width: '18px', height: '18px'}} />  
                <span className="ms-2" >Reset password</span> 
            </button>
        </>
    )
}

export default ResetPassword 

