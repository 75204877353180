import React, { useState, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../components/Button' 
import { cardApproval, refreshToken, killToken, saveToken } from '../security/AuthService'
import { useNavigate } from 'react-router-dom'
import './CSS/approvecard.css'  

import { refreshTokenWithAPICallHandler } from '../utils/refresh'
const ApproveCard = (props) => { 
    const [responseAPI, setResponseAPI] = useState('')  
    const navigate = useNavigate()  
    const [show, setShow] = useState(true) 

    const handleApproveTransaction = useCallback(async function () {
        try {
            if(responseAPI !== '') {
                setResponseAPI('')
            }
            setShow(false)  
            let approve_card = {
                api_call: await cardApproval( 
                    {
                        approve_status: "1"
                    }
                )
            }
            const renew = async ()=>{
                return await cardApproval( 
                    {
                        approve_status: "1"
                    }
                )
            } 
            // If fetch returns 401 
            if(approve_card.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(approve_card, renew) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login') 
                }
            }
            const result_approve_card = await approve_card.api_call.json()  
            setResponseAPI(result_approve_card.message) 
        } catch(err) {

        }
    }, [responseAPI])

    const handleDeclineTransaction = useCallback( async function () { 
        try {
            if(responseAPI !== '') {
                setResponseAPI('')
            }  
            setShow(false)  
            let decline_card = {
                api_call: await cardApproval( 
                    {
                        approve_status: "2"
                    }
                )
            }
            const renew = async ()=>{
                return await cardApproval( 
                    {
                        approve_status: "2"
                    }
                )
            }
            // If fetch returns 401 
            if(decline_card.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(decline_card, renew) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login') 
                }

            } 
            const result_decline_card = await decline_card.api_call.json() 
            setResponseAPI(result_decline_card.message)  
        } catch(err) {

        } 
    }, [responseAPI])    

    const handleCancelApproveTransaction = useCallback( async function () {
        setResponseAPI('')
        props.handleClose() 
        await new Promise((resolve, reject)=>{
        setTimeout(()=>{
            resolve(setShow(true)) 
        }, 500)
    })
    }, [])  
      
    return (
        <Modal show={props.show} onHide={props.handleClose} className='cardApproval' backdrop='static' keyboard='false'>
            <div>
                <figure>
                    <img src='/images/dashboard/mycard/E-Wallet-pana 1.svg' alt='wallet with crypto coins and fiat money' draggable='false' />
                </figure>
                <div className='transactionCheckoutApprovalTitle'>Transaction checkout approval</div>
                {responseAPI && <div>{responseAPI}</div>}
            </div>
            <div className='group-btn'>
                
                
                {show && <>
                            <Button click={handleDeclineTransaction} styles='declineTransaction'>Decline Transaction</Button>
                            <Button click={handleApproveTransaction} styles='approveTransaction'>Approve Transaction</Button>
                        </>
                } 
                <Button click={handleCancelApproveTransaction} styles='cancelApproveTransaction'>Close</Button> 
                
            </div>
        </Modal> 
      )
}

export default ApproveCard
