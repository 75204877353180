import React, { useState, useEffect, useCallback, useRef, memo } from 'react' 
import './CSS/topup.css'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button' 
import { convertToUSDT, requestTopUp } from '../security/AuthService' 
import { useNavigate } from 'react-router-dom' 
import { useActive } from '../context/ActiveContext' 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 

const Topup = memo((props) => {
    const [amount, setAmount] = useState('--')
    const [totalAmount, setTotalAmount] = useState('--') 
    const navigate = useNavigate() 
    const { handleMenuActive } = useActive()  

    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value) 
        state.setFieldTouched(e.target['name'])
    }

    useEffect(()=>{
        try {
            if(document.getElementById('topup_amount')) {
                document.getElementById('topup_amount').addEventListener('keyup', ()=>{
                    handleChange() 
                })}
        } catch(err) {

        }

    } )  

    async function handleChange() {
        
       try {
        
            let topup_fee = parseFloat(localStorage.getItem('t_u_f'))
            if(document.getElementById('topup_amount').value === '') {
                localStorage.setItem('t_u_f', 0)   
                setAmount('--')
                setTotalAmount('--') 
            }
            if( /^[0-9\.]+$/.test(document.getElementById('topup_amount').value)) {
                
                     
                    let convert = {
                        api_call: await convertToUSDT({
                            amount: parseFloat(document.getElementById('topup_amount').value) 
                        })
                    }
                    const renew = async () =>{
                        return await convertToUSDT({
                            amount: parseFloat(document.getElementById('topup_amount').value) 
                        })
                    }
                    // If fetch returns 401 
                    if(convert.api_call.status === 401) {  
                        if(await refreshTokenWithAPICallHandler(convert, renew) === 'REDIRECT_TO_LOGIN') {
                            return navigate('/login') 
                        }

                    }
                    const convert_data = await convert.api_call.json()  

                    if(document.getElementById('topup_amount').value === '') {
                        setAmount('--')
                        setTotalAmount('--') 
                    } else {
                        if(document.getElementById('topup_amount').value >= 100) { 
                            localStorage.setItem('t_u_f', localStorage.getItem('t_u_f_100')) 
                            topup_fee = parseFloat(localStorage.getItem('t_u_f')) 
                        } else if((document.getElementById('topup_amount').value >= 50) && (document.getElementById('topup_amount').value < 100)) {
                            localStorage.setItem('t_u_f', localStorage.getItem('t_u_f_50_100'))  
                            topup_fee = parseFloat(localStorage.getItem('t_u_f'))  
                        }
                        setAmount((parseFloat(document.getElementById('topup_amount').value))) 
                        setTotalAmount((((parseFloat(convert_data['data']['result'])*topup_fee)/100) + parseFloat(convert_data['data']['result'])))  
                                          
                           
                    }
            } else {
                localStorage.setItem('t_u_f', 0)   
                setAmount('--')
                setTotalAmount('--')
            } 
       } catch(err) {
       }
    } 
    const handleCancel = useCallback(async function() {
        let path = window.location.pathname;
        path = path.split("/");
        path = path[path.length - 1] 

        if(path === 'topupcard') {
            navigate('/mycard')   
            handleMenuActive('My Card')    
            handleMenuActive('My Card')      

        }
        setAmount('--')
        setTotalAmount('--')
        props.handleClose()
        await new Promise((resolve, reject)=>{
            setTimeout(()=>{
                resolve(localStorage.setItem('t_u_f', 0))
            }, 100)
        })
    }, [])  
    
  return (
     <Modal show={props.show} onHide={props.handleClose} className='topup-modal' backdrop='static' keyboard='false' >
        <div className='modal-header'>
            <div className='topup-title'>Top-up</div>
            <div className='topup-description'>Enter the amount in USD you want to add. This amount will be available on your card in USD.</div>
        </div> 
    
        <Formik
                initialValues={{
                    topup_amount: ''
                }}
                validateOnChange={ true }
                validateOnBlur={ true } 
                validationSchema={YUP.object({
                    topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid top-up amount</span>}).test('topup', `Please enter at least ${parseFloat(localStorage.getItem('m_t_u'))}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_t_u'))),   
                })}

                onSubmit={async (values)=>{ 
                    try {
                        let path = window.location.pathname;
                        path = path.split("/");
                        path = path[path.length - 1]   
                        
                        let topup = {
                            api_call: await requestTopUp({
                                amountFiat: parseFloat(values.topup_amount), 
                                amountCryptoTotal: parseFloat(totalAmount)
                        })
                        }
                        const renew = async () => {
                            return await requestTopUp({
                                amountFiat: parseFloat(values.topup_amount), 
                                amountCryptoTotal: parseFloat(totalAmount)
                        })
                        }
                        // If fetch returns 401 
                        if(topup.api_call.status === 401) {
                            if(await refreshTokenWithAPICallHandler(topup, renew) === 'REDIRECT_TO_LOGIN') {
                                return navigate('/login') 
                            } 
                        }

                        if(topup.api_call.ok) {
                            const result = await topup.api_call.json()   
                            if(path === 'topupcard') {
                                       
                                props.handleClose() 
                            } 
                            if(path === 'mycard') {
                                props.handleClose()
                                props.updateBalance(result['data']['balance']) 
                            }
                            if(path === 'dashboard') {
                                props.handleClose()   
                                props.updateBalance(result['data']['balance']) 
                                props.updateBalanceUsd(result['data']['balance'])       
                            }
                            props.handleShowTopupSuccess()  
                            await new Promise((resolve, reject)=>{
                                setTimeout(()=>{
                                    resolve(localStorage.setItem('t_u_f', 0))
                                }, 100)
                            }) 
                        }

                        if(!topup.api_call.ok) {
                            props.handleClose()
                            props.handleShowInsufTopupBalance()  
                        }
                        setAmount('--')
                        setTotalAmount('--') 
                                    
                        } catch(err) {
                        }
                }}
                        >
                        {(formik) => {
                        state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                                <TextInput 
                                    name='topup_amount' 
                                    id='topup_amount' 
                                    label='Enter amount' 
                                    inputtype='text' 
                                    onKeyUp={keyUpHandler} 
                                    currency='usd'  
                                />   
                                <div className='amount-fees-wrapper'> 
                                    <div className='amount-fees-row'><span>Available balance</span><span>{`${props.balanceUSDT.toFixed(2)}`} <strong>USDT</strong> | {props.balanceUsd.toFixed(2)} <strong>USD</strong></span></div>                                      
                                    <div className='amount-fees-row'><span>Amount</span><span>{!isNaN(parseFloat(amount)) ? parseFloat(amount).toFixed(2) : '--'} USD</span></div>
                                    <div className='amount-fees-row'><span>Fees</span><span>{localStorage.getItem('t_u_f')}%</span></div> 
                                    <div className='amount-fees-row'><span>Total</span><span>{!isNaN(parseFloat(totalAmount)) ? parseFloat(totalAmount).toFixed(2) : '--'} USDT</span></div> 
                                </div>
                                <div className='transaction-fee-notification'>
                                    * Our transaction fee are included. <span>See transaction fee</span>
                                </div>
                                <div className='group-btn'>
                                    <Button click={handleCancel} id="cancel">Cancel</Button>
                                    <Button btnType='submit' id='confirm_topup' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting }>Confirm</Button>     
                                </div>
                                    
                            </Form>)}}
        </Formik> 
   </Modal> 
  )
}
) 

export default Topup 
