import React, { useCallback, useEffect, useState } from 'react'
import './CSS/secureyouraccount.css'
import { generateQRCode } from '../utils/utils'  
import { Formik, Form } from 'formik'
import * as YUP from 'yup'  
import TextInput from '../components/TextInput' 
import PasswordInput from '../components/PasswordInput' 
import { useNavigate, useLoaderData } from 'react-router-dom'
import { completeLinkToGoogleAuth, findRequestCard } from '../security/AuthService'                                      
import ErrorLinkingAuth from '../Modals/ErrorLinkingAuth' 
import { useReferral } from '../context/ReferralContext'; 
import { useGoogleAuthentication } from '../context/GoogleAuthenticationContext' 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 

const SecureYourAccount = () => { 
    const { showReferral, handleShowReferral } = useReferral()  
    const { enableGoogleAuthenticationHandler } = useGoogleAuthentication()   
    const [isCopied, setIsCopied] = useState(false) 
    const navigate = useNavigate() 
    const [linkingError, setLinkingError] = useState({
        showError: false,
        error: '' 
    }) 
    const {secretKey, qrCodeUrl} = useLoaderData() 
    useEffect(()=>{
        generateQRCode(qrCodeUrl, 'qrCode2fa')  
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
        if(decoded_payload['req'] !== 'S') {
            findCardRequest()
        } else {
            if(!showReferral) {
            handleShowReferral() 
            }
        }                                                                              
    }, []) 

    async function findCardRequest () {

        let fetch_card_request = {
            api_call: await findRequestCard() 
        }
        // If fetch returns 401 
        if(fetch_card_request.api_call.status === 401) {
            const renewFCR = async ()=>{
                return await findRequestCard()
            }
            if(await refreshTokenWithAPICallHandler(fetch_card_request, renewFCR) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login')  
            }
        }
        const result_fetch_card_request = await fetch_card_request.api_call.json() 

        if(result_fetch_card_request['data'].length > 0) {
            if(result_fetch_card_request['data'][0]['requesteStatus'] === 'Success') {
                handleShowReferral() 
            }
        }
    }

    async function copyAddress () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('secretKey').innerHTML)  
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
        } catch (err) {
            setIsCopied(false)
        }
    } 
    const closeError = useCallback(()=>{
        setLinkingError({
            ...linkingError,
            showError: false 
        })
    }, []) 
  return (
    <>
        <div className='secureYourAccountContainer'> 
            <figure>
                <img 
                    src='/images/dashboard/2fa/Frame.svg'   
                    alt='A blue frame with a title Secure your account with Google Authenticator then its description, followed by the steps of 2fa binding with the title of the steps as follows: Download the app, Scan a QR code or enter a key and Complete link. To the right of the frame, there is a person sitting on a chair, holding a PC with Paytaps logo and a phone, behind him a screen containing verification text and 6 rectangular fields, the first 4 contain 5, 3, 3, 8 respectively, and the remaining 2 fields are empty without content'                                                                                                                                                            
                    draggable='false'
                    loading='eager'  
                />

                <img 
                    src='/images/dashboard/2fa/Frame 1.svg'  
                    alt='A blue frame with a title Secure your account with Google Authenticator then its description, followed by the steps of 2fa binding with the title of the steps as follows: Download the app, Scan a QR code or enter a key and Complete link'                                                                                                                                                           
                    draggable='false'
                    loading='eager'  
                />     
            </figure>
        
            <main className='secureYourAccountMainContent'>  
                <section className='donwloadGoogleAuthenticatorAppWrapper'>
                    <div className='sectionTitle'>1. Download the Google Authenticator App</div>
                    <p className='sectionParagragh'>Install the Google Authenticator app on your mobile device. It's available for free on the Google Play Store and Apple App Store.</p> 
                    <div className='group-link'>   
                        <a href='https://apps.apple.com/fr/app/google-authenticator/id388497605'> 
                            <img 
                                src='/images/dashboard/2fa/tabler_brand-apple-filled.svg' 
                                alt='white apple icon' 
                                draggable='false' 
                            />
                            <span>Apple Store</span> 
                        </a>  
                        <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en-US'> 
                            <img 
                                src='/images/dashboard/2fa/simple-icons_googleplay.svg' 
                                alt='white google play store icon'
                                draggable='false'  
                            />
                            <span>Google Play</span>
                        </a>   
                    </div>
                </section>

                <section className='scanQRCodeOrEnterKeyWrapper'> 
                    <div className='sectionTitle'>2. Scan QR Code or Enter Key Manually</div>
                    <p className='sectionParagragh'>Open the Google Authenticator app and select Add Account. You can either scan the QR code displayed on your account settings page using your phone’s camera or manually enter the provided secret key</p> 
                    <div id='qrCode2fa'></div>
                    <div className='secretKeyWrapper'>
                        <div className='secretKeyInnerWrapper'>
                            <img 
                                src='/images/dashboard/2fa/Group.svg' 
                                alt='a rectangle with a blue border, a white background, and inside it a blue key'  
                                draggable='false'  
                            /> 
                            <span id='secretKey'>{secretKey}</span>  
                        </div>
                        <button className='copy-btn' type='button' onClick={copyAddress}> 
                            <img 
                                src='/images/dashboard/topup/tabler_copy.svg' 
                                alt='2 squares with the highest one its right half clipped' 
                                draggable='false'  
                                hidden={isCopied} 
                            />  
                            <span hidden={!isCopied}>Copied!</span>  
                        </button> 
                    </div>
                </section>

                <section className='completeLinkingWrapper'> 
                    <div className='sectionTitle'>3. Complete the Linking</div>
                    <p className='sectionParagragh'>Once the app generates a 6-digit code, enter it into the verification field on your account settings page. Click Verify to confirm and complete the process.</p>  
                    <Formik
                                initialValues={{
                                    password: '',
                                    authenticationCode: '' 
                                }}

                                validationSchema={YUP.object({
                                    password: YUP.string().required('Required!'),
                                    authenticationCode: YUP.string().required('Required!').min(6, 'The authentication code must contain 6 digits').max(6, 'The authentication code must contain 6 digits')   
                                })}

                                onSubmit={ async (values, {setSubmitting, resetForm})=>{
                                try {
                                    
                                    let post_complete_linking_google_auth = {
                                        api_call: await completeLinkToGoogleAuth({
                                            password: values.password,
                                            auth_code: values.authenticationCode 
                                        })
                                    }
                                    // If fetch returns 401
                                    if(post_complete_linking_google_auth.api_call.status === 401) {
                                        const renewLTA = async ()=>{
                                            return await completeLinkToGoogleAuth({
                                                password: values.password,
                                                auth_code: values.authenticationCode 
                                            })
                                        }
                                        if(await refreshTokenWithAPICallHandler(post_complete_linking_google_auth, renewLTA) === 'REDIRECT_TO_LOGIN') {
                                            return navigate('/login')  
                                        }
                                    }
                                    const result_post_complete_linking_google_auth =  await post_complete_linking_google_auth.api_call.json() 

                                    if(post_complete_linking_google_auth.api_call.ok && (result_post_complete_linking_google_auth.message === 'successfully')) {
                                        enableGoogleAuthenticationHandler() 
                                        return navigate('/profile') 
                                    } else if(!post_complete_linking_google_auth.api_call.ok && (result_post_complete_linking_google_auth.message === 'Invalid code')) {
                                         
                                        setLinkingError({
                                            ...linkingError,
                                            showError: true,
                                            error: 'Invalid code' 
                                        })
                                    } else if(!post_complete_linking_google_auth.api_call.ok && (result_post_complete_linking_google_auth.message === 'Invalid Password')) {
                                        setLinkingError({
                                            ...linkingError,
                                            showError: true,
                                            error: 'Invalid Password' 
                                        })
                                    }
                                    
                                } catch(err) {

                                }
                                }}
                            >
                                
                                {(formik) => (
                                <Form className='form' onSubmit={formik.handleSubmit}> 
                                    <PasswordInput name='password' id='password' label='Password'  /> 
                                    <TextInput name='authenticationCode' id='authenticationCode' label='Authentication Code'  />  
                                    <button
                                    type='submit'
                                    id='verify2faLinking'  
                                    disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
                                    >
                                        Verify 
                                    </button>
                                </Form>
                )}      
                </Formik> 
                </section>
            </main>  
        </div>
        <ErrorLinkingAuth show={linkingError.showError} close={closeError} error={linkingError.error} />  
    </>
  )
}

export default SecureYourAccount