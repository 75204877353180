import React, { useState, useEffect, useCallback } from 'react'  
import Topup from '../Modals/Topup'  
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up' 
import { userBalance, convertToUSDT } from '../security/AuthService' 
import TopupSuccess from '../Modals/TopupSuccess'
import { useReferral } from '../context/ReferralContext'; 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 
import { useNavigate } from 'react-router-dom'

const TopUpCard = () => {
    const [show, setShow] = useState(false) 
    const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)  
    const [balanceUSDT, setBalanceUSDT] = useState(0)
    const [balanceUsd, setBalanceUsd] = useState(0) 
    const [showSuccess, setShowSuccess] = useState(false)                                                                                   
    const { showReferral, handleShowReferral } = useReferral() 
    const navigate = useNavigate() 
    const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
    const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);

    useEffect(()=>{
        getBalanceUSDTEUR() 
    }, []) 
    const handleClose = useCallback(function() {
        setShow(false) 
    }, []) 

    const handleCloseTopupSuccess = useCallback(function () {
        setShowSuccess(false) 
    }, [])
    const handleShowTopupSuccess = useCallback(function () {
        setShowSuccess(true)
    }, [])

    async function getBalanceUSDTEUR () {
        let user_balance = {
            api_call: await userBalance()
        }
        // If fetch returns 401
        if(user_balance.api_call.status === 401) {
            const renewB = async ()=>{
                return await userBalance()
            }
            if(await refreshTokenWithAPICallHandler(user_balance, renewB) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login')  
            }
        }
        const balance_usdt = await user_balance.api_call.json()

        // Start of verification if referral should appear or no
            if(!showReferral) {
                handleShowReferral() 
            }
        // End   

        if(user_balance.api_call.ok) {
            setBalanceUSDT(balance_usdt['data'][0]['balance'])  
        } 
        
        let convert = {
            api_call: await convertToUSDT({
                amount: balance_usdt['data'][0]['balance'] 
            })
        }
        // If fetch returns 401
        if(convert.api_call.status === 401) {
            const renewCTU = async ()=>{
                return await convertToUSDT({
                    amount: balance_usdt['data'][0]['balance'] 
                })
            }
            if(await refreshTokenWithAPICallHandler(convert, renewCTU) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login')  
            }
        }
        const convert_result = await convert.api_call.json()

        if(convert.api_call.ok) {
            setBalanceUsd(convert_result['data']['result'])   
        } 
        setShow(true) 
    }

    
  return (
    
    <>
        <Topup 
            show={show} 
            handleClose={handleClose} 
            handleShowInsufTopupBalance={handleShowInsufTopupBalance} 
            balanceUsd={balanceUsd} 
            balanceUSDT={balanceUSDT} 
            handleShowTopupSuccess={handleShowTopupSuccess} 
        />                                                          
        <InsufficientBalance_top_up 
            show={showInsufficientTopupBalance} 
            handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} 
            handleShowInsufTopupBalance={handleShowInsufTopupBalance} 
        />   
        <TopupSuccess show={showSuccess} handleClose={handleCloseTopupSuccess} />                                                                                                                                                                                                                    
    </>
  )
}

export default TopUpCard  
