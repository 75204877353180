import { refreshToken, killToken, saveToken } from "../security/AuthService";

export async function refreshTokenHandler () {
    try {
        const resultRefresh = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})
    
        if(resultRefresh.status === 401) {
            killToken()
            return 'REDIRECT_TO_LOGIN'
        }

        const result = await resultRefresh.json() 

        let tokenPayload = result.token.split('.')[1] 

        let new_decoded_payload = JSON.parse(atob(tokenPayload)) 
        if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
            killToken()
            return 'REDIRECT_TO_LOGIN'
 
        } else {
            saveToken(result.token, result.refresh_token) 
        }
    } catch(err) {
    }
}  

export async function refreshTokenWithAPICallHandler (apiFetchResult, apicall) {
    const refreshTokenResult = await refreshToken({refresh_token: localStorage.getItem('u_r_t')})
    
    // If refresh token returns 401
    if(refreshTokenResult.status === 401) {
        killToken()
        return 'REDIRECT_TO_LOGIN'
    } 

    // If refresh token is successfull 
    const payloadRefreshToken = await refreshTokenResult.json()   

    let tokenPayload = payloadRefreshToken.token.split('.')[1] 

    // Payload of new generated token 
    let new_decoded_payload = JSON.parse(atob(tokenPayload)) 

    if(new_decoded_payload['exp']*1000 < new Date().valueOf())  {
        killToken()
        return 'REDIRECT_TO_LOGIN' 
    } else {
        saveToken(payloadRefreshToken.token, payloadRefreshToken.refresh_token)               
        apiFetchResult.api_call = await apicall()  
    }
}
