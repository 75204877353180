import React, { useCallback } from 'react'
import { Modal } from 'react-bootstrap' 
import Button from '../components/Button'  
import { useNavigate } from 'react-router-dom' 
import { useActive } from '../context/ActiveContext'
import './CSS/suretocontinuetopup.css'

const InsufficientBalance_top_up = (props) => {
  const navigate = useNavigate()  
  const { handleMenuActive } = useActive() 
     
    const depositRedirectionHandler = useCallback(function() {
      navigate('/depositfund') 
      handleMenuActive('Deposit Fund') 
    })
    const handleCancel = useCallback(async function() {
      let path_ = window.location.pathname;
      path_ = path_.split("/");
      path_ = path_[path_.length - 1]
      if(path_ === 'topupcard') {
        navigate('/mycard')  
        props.handleCloseInsufTopupBalance()
        handleMenuActive('My Card') 
        handleMenuActive('My Card') 
      } else { 
        props.handleCloseInsufTopupBalance()
      } 
      
      await new Promise((resolve, reject)=>{
        setTimeout(()=>{
            resolve(localStorage.setItem('t_u_f', 0))
        }, 100)
      }) 

    }, [])  
  return (
    <Modal show={props.show} onHide={props.handleClose} className='confirmation' backdrop='static' keyboard='false'>
    <div>
        <figure>
            <img src='/images/dashboard/mycard/Group 318 (1).svg' alt='wallet with crypto coins and fiat money' draggable='false' />
        </figure>
        <div className='sure-to-continue-top-up'>Insufficient  Balance </div>
        <div>Your current balance is not enough to complete this transaction. Please add the necessary funds to your wallet and try again.</div>
    </div>
    <div className='group-btn'>
        <Button click={handleCancel}>Cancel</Button>
        <Button click={depositRedirectionHandler}>Deposit</Button>
    </div>
        
      </Modal>
  )
}

export default InsufficientBalance_top_up
 