import React, { useState, useEffect, useRef, useCallback} from 'react'
import WalletDeposit from '../Modals/WalletDeposit'
import UnsufficientBalance from '../Modals/UnsufficientBalance' 
import { useLoaderData } from 'react-router-dom' 
import { useStateCountDown } from '../context/EnableCountDown'   
import { findRequestCard } from '../security/AuthService';
import { useReferral } from '../context/ReferralContext'; 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 
import { useNavigate } from 'react-router-dom'

const DepositFund = () => {
    const navigate = useNavigate() 
    const {stateCountDown, setStateCountDown} = useStateCountDown()
    const [normalDeposit, setNormalDeposit] = useState(false)
    const result = useLoaderData() 
    const [unsufficientBalanceState, setUnsufficientBalanceState] = useState(false)
    const refAllow = useRef(false) 
    const { showReferral, handleShowReferral } = useReferral()  

    useEffect(()=>{ 
        handleShowNormalDeposit()
      }, [handleShowNormalDeposit])

    useEffect(()=>{
      const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
      if(decoded_payload['req'] !== 'S') {
        findCardRequest()
      } else {
        if(!showReferral) {
          handleShowReferral() 
        }
      } 
    }, [])  

    async function findCardRequest () {
      let fetch_card_request = {
        api_call: await findRequestCard() 
      }
      const renewFCR = async ()=>{
        return await findRequestCard()
      }
      // If fetch returns 401 
      if(fetch_card_request.api_call.status === 401) {
        if(await refreshTokenWithAPICallHandler(fetch_card_request, renewFCR) === 'REDIRECT_TO_LOGIN') {
          return navigate('/login')  
        }
      }
      const result_fetch_card_request = await fetch_card_request.api_call.json()

      if(result_fetch_card_request['data'].length > 0) {
          if(result_fetch_card_request['data'][0]['requesteStatus'] === 'Success') {
              handleShowReferral() 
          }
      }
    }

    const handleCloseNormalDeposit = () => {setNormalDeposit(false); setStateCountDown(false)} 

    function handleShowNormalDeposit(){
      if(result.normal_deposit) {
        setNormalDeposit(true); 
        setStateCountDown(true)
      } else if(!result.normal_deposit) {
        handleShowUnsBalance() 
        refAllow.current = true 
      } 
    }

    function setRefFalse() {
      setStateCountDown(false)
    }

    function setRefTrue() {
      setStateCountDown(true)
    }

    const handleShowUnsBalance = useCallback(function() {
        setUnsufficientBalanceState(true)
    }, [])

    const handleCloseUnsBalance = useCallback(function() {
      setUnsufficientBalanceState(false)
    }, [])

  return (
    <>
    {(result?.normal_deposit) && <WalletDeposit show={normalDeposit} handleClose={handleCloseNormalDeposit} stateCountDown={stateCountDown} setRefFalse={setRefFalse} setRefTrue={setRefTrue}  /> }   
    {(!result?.normal_deposit) && <UnsufficientBalance show={unsufficientBalanceState} handleClose={handleCloseUnsBalance} a={refAllow.current}  />    }                                  
    </>
  )
}

export default DepositFund
