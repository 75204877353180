import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import './CSS/productoverview.css'

const ProductOverview = () => {
  return (
    <section id='product-overview'>
        <div className='product-overview-container'>
            <div className='product-overview-title'>PRODUCT OVERVIEW</div> 
            <h2 className='product-overview-subtitle'>PAYTAPS Crypto Visa Card : Your Reliable Digital Companion</h2>
            <div className='product-overview-details-wrapper'>
                <div className='product-overview-details-wrapper-text-content'>
                    <ScrollAnimation animateIn="fadeIn">
                        <p className='product-overview-details-wrapper-text-content-description' >
                        The PAYTAPS Card is your pass to a world of financial independence; it's  far from a simple cryptocurrency card.
                        The Visa Card payment system is perfectly connected with our digital debit card, the PAYTAPS Card.
                        With Visa Card's strong  security and fraud protection, you can manage your digital assets more effectively than  before.
                        </p>
                        <div className='product-overview-details-wrapper-text-content-key-highlights'>
                            <span className='key-highlight'>Key Highlights:</span> 
                            <ul>
                                <li><span className='key-highlight'>Contactless Payments:</span> <span className='key-description'>Make payments in-store and online seamlessly.</span></li>
                                <li><span className='key-highlight'>Rapid Reloads:</span> <span className='key-description'>Top up your card quickly and easily.</span></li>
                                <li><span className='key-highlight'>Mobile Wallet Integration:</span> <span className='key-description'>Use Apple Pay, Google Pay, and Samsung Pay
                                for secure transactions.</span></li>
                            </ul> 
                        </div>
                    </ScrollAnimation>
                </div>
                <figure className='product-overview-details-wrapper-image-content'>
                    <img src='/images/landingpage/body/Frame 6 (6).png' alt='dashboard details' draggable='false' />   
                </figure>
            </div>
        </div>
    </section>
  )
}

export default ProductOverview 