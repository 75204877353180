import React, { useEffect } from 'react'
import './CSS/resetpasswordsuccess.css'
import { findRequestCard } from '../security/AuthService'
import { useReferral } from '../context/ReferralContext'
import { useNavigate } from 'react-router-dom' 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 

const ResetPasswordSuccess = () => {
    const navigate = useNavigate() 
    const { showReferral, handleShowReferral } = useReferral()

    useEffect(()=>{
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
        if(decoded_payload['req'] !== 'S') {
            findCardRequest() 
        } else {
            if(!showReferral) {
            handleShowReferral() 
            } 
        }
    }, []) 

    async function findCardRequest () { 
        let fetch_card_request = {
            api_call: await findRequestCard() 
        }
        // If fetch returns 401 
        if(fetch_card_request.api_call.status === 401) {
            const renewFCR = async ()=>{
                return await findRequestCard()
            }
            if(await refreshTokenWithAPICallHandler(fetch_card_request, renewFCR) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login')  
            }
        }
        const result_fetch_card_request = await fetch_card_request.api_call.json() 

        if(result_fetch_card_request['data'].length > 0) {
            if(result_fetch_card_request['data'][0]['requesteStatus'] === 'Success') {
                handleShowReferral() 
            }
        }
    }

  return (
    <div className='resetPasswordSuccessContainer'>
            <div className='resetPasswordSuccessWrapper'>
                <figure>
                    <img 
                        src='/images/dashboard/resetpassword/Group 442.svg'  
                        alt='a men hold a big key beside it a blue rectangle within it password updated sentence and a checkmark' 
                        style={{width: '100%', maxWidth: '500px', display: 'block', margin: 'auto'}} 
                        draggable='false'  
                    />  
                </figure>
                <div className='resetPasswordSuccessContent'>
                    <div className='resetPasswordSuccessContentTitle'>Password updated!</div>
                    <p className='resetPasswordSuccessContentDescription'>Your password has been changed successfully. </p>  
                </div>
            </div>
        </div>
  )
}

export default ResetPasswordSuccess 
