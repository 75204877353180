import React, { useState, useEffect, useRef, useCallback  } from 'react' 
import './CSS/unsufficientbalance.css'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import TextInput from '../components/TextInput'
import * as YUP from 'yup'
import Button from '../components/Button'
import Loader from '../jsx/pages/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { generateAddress, regenerateAddress, initialDeposit } from '../security/AuthService'   
import QRCodeStyling from "qr-code-styling";   
import ConfirmTermsConditions from './ConfirmTermsConditions' 
import Select from '../components/Select' 
import { useActive } from '../context/ActiveContext' 
import Carousel from 'react-bootstrap/Carousel';                           
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 

const UnsufficientBalance = (props) => {
    const [showInstruction, setShowInstruction] = useState(false)  
    const handleCloseInstruction = () => {setShowInstruction(false)}  
    const handleShowInstruction = () => setShowInstruction(true)         
    const { handleMenuActive } = useActive() 
    const navigate = useNavigate()
    const [isCopied, setIsCopied] = useState(false)
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [timer, setTimer] = useState(`${localStorage.getItem('t_d_d') ? (localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00') : ''}  `)
    const [refisTimerEnabled, setRefisTimerEnabled] = useState(true)
    const [requestRefresh, setRequestRefresh] = useState(false)
    const [addressValue, setAddressValue]= useState('')
    const [loadAdd, setLoadAdd] = useState(false)
    const [error, setError] = useState(false) 
    const cancel = useRef(false) 

    const refTimerIns = useRef(null)

    const [termsConditions, setTermsConditions] = useState(false)   
    const [confirm_TC, setConfirm_TC] = useState(false)
    
    const [accountType, setAccountType] = useState('Blockchain')  

    const handleShowrequestRefresh = useCallback(function(){
        setRequestRefresh(true)
    }, [])

    const handleCloserequestRefresh = useCallback(function(){
        regenerateAddressWallet()
        refTimerIns.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000
        setRefisTimerEnabled(true); 
        setRequestRefresh(false)
        setShowInstruction(false)
        setAccountType('Blockchain') 

    }, [])

    async function copyAddress () {
        try {
            setIsCopied(true)
            await navigator.clipboard.writeText(document.getElementById('wallet-address-value').innerHTML)
            await new Promise((resolve, reject)=>{
                setTimeout(()=>{
                    resolve(setIsCopied(false))
                }, 2000)
            })
        } catch (err) {
            setIsCopied(false)
        }
    }

    let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }

    async function generateAddressWallet() {
        try {
            // loadAddress()
            setLoadAdd(true)
            let result = {
              api_call: await generateAddress()
            }
            const renew = async ()=>{
              return await generateAddress()
            }
            // If fetch returns 401 
            if(result.api_call.status === 401) {
              if(await refreshTokenWithAPICallHandler(result, renew) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login') 
              }
            } 
            const message =  await result.api_call.json()
            cancel.current = false

             if(result.api_call.ok && message.message === 'success' && message.data[0]['value']) {
                //  getAddressValue() 
                setLoadAdd(false)
                setAddressValue(message.data[0]['value'])
                refTimerIns.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000

             } else {
                setLoadAdd(false)
                setAddressValue('')
             }
        } catch(err) {
        }
    }

    async function regenerateAddressWallet() {
        try {
            // loadAddress()
            setLoadAdd(true)

            let result = {
              api_call: await regenerateAddress()
            }
            const renew = async ()=>{
              return await regenerateAddress()
            }
            // If fetch returns 401 
            if(result.api_call.status === 401) {
              if(await refreshTokenWithAPICallHandler(result, renew) === 'REDIRECT_TO_LOGIN') {
                return navigate('/login') 
              }
            }
            const message =  await result.api_call.json()
            
             if(result.api_call.ok && message.message === 'success' && message.data[0]['value']) { 
                setLoadAdd(false)
                setAddressValue(message.data[0]['value'])
                refTimerIns.current = new Date().getTime() + (parseInt(localStorage.getItem('t_d_d')) * 60 * 1000) + 1000 

             } else {
                setLoadAdd(false)
                setAddressValue('')
             }
        } catch(err) {
          
        }
    }
    useEffect(()=>{
            generateAddressWallet()
    }, [])

    useEffect(()=>{
        if(props.a) {
        if(timer === '-1:-1' || !refisTimerEnabled) {
            handleShowrequestRefresh() 
          setTimer(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `) 
        }
        
        if(refisTimerEnabled && addressValue) {

        
        var x = setInterval(function() {

            // Get today's date and time
            const now = new Date()
            
              
            // Find the distance between now and the count down date
            var distance = refTimerIns.current - now.getTime();
              
            // Time calculations for minutes and seconds
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setTimer(`${(''+minutes).length === 1 ? '0' + minutes : minutes}:${(''+seconds).length === 1 ? '0' + seconds : seconds}`) 
              
            // If the count down is over, write some text 
            if (distance < 0) {
              clearInterval(x);
              setRefisTimerEnabled(false)
              
              setTermsConditions(false)
              setConfirm_TC(false)               
            }
            if(cancel.current) {
              setRefisTimerEnabled(true)
          }
          }, 1000);
        }
    }
    }, [timer, refisTimerEnabled, addressValue, handleShowrequestRefresh, props.a, cancel.current]) 
    
    const handleCloseTermsTC = useCallback(function() {
        setConfirm_TC(false)
      }, [])

      const generateQRCode = (data) => { 
        const qrCode = new QRCodeStyling({ 
          width: 126, 
          height: 130, 
          data: data, 
          image: "https://paytaps.com/images/dashboard/header/paytaps%20icon.svg",   
          dotsOptions: { 
            gradient: { 
              type: "radial",  
              colorStops: [ 
                { offset: 0, color: "#4196FC" },
                { offset: 0.5, color: "#4A61D4" },
                { offset: 1, color: "#5520A3" }    
              ], 
            }, 
            type: "rounded",  
          }, 
          backgroundOptions: { 
            gradient: { 
              type: "radial", 
              colorStops: [ 
                { offset: 0, color: "#ffffff" },  
                { offset: 1, color: "#ffffff" },  
              ], 
            }, 
          }, 
          imageOptions: { 
            margin: 5,  
            scale: 0.4,                                                                  
          }, 
          cornersSquareOptions: { 
            color: "#5520A3",  
            type: "extra-rounded",  
          }, 
          cornersDotOptions: { 
            color: "#5520A3",  
            type: "rounded",  
          }, 
        }); 
      
        qrCode.append(document.getElementById("qrcode"));         
      };

      useEffect(() => { 
          if(!loadAdd) {
            generateQRCode(addressValue)
          }
      }, [loadAdd])        
       
      const handleShowTermsConditions = useCallback(function() {
        setTermsConditions(true) 
      }, [])
    
      const handleCloseTermsConditions = useCallback(function() {
        setTermsConditions(false)   
      }, []) 
      const handleConfirmTermsConditions = function(e) {           
          if(!termsConditions) {
            if(confirm_TC) {
              return setConfirm_TC(false) 
            }
          handleShowTermsConditions()    
  
        } else {
          if(confirm_TC) {
            return setConfirm_TC(false) 
          }
          handleCloseTermsConditions() 
             
        }
    }
    const handleAccountType = useCallback(function(payload) {
      setAccountType(payload) 
    }, [])  

    function handleResetForm(e) {
        state.resetForm()
        state.setFieldValue('hash_trx', '')
        state.setFieldValue('uid', '')   
        setTermsConditions(false)
        setConfirm_TC(false)  

    } 

    const cancelFirstDeposit = useCallback(function() {
      let path_ = window.location.pathname;
      path_ = path_.split("/");
      path_ = path_[path_.length - 1]
      if(path_ === 'depositfund') {
          navigate('/dashboard')
          handleMenuActive('Dashboard') 
          handleMenuActive('Dashboard') 

      }
      if(path_ === 'ordercard') { 
        navigate('/dashboard')
        handleMenuActive('Dashboard') 
        handleMenuActive('Dashboard') 
      }
      cancel.current = true
      refTimerIns.current = 0; 
      setRequestRefresh(false); 
      setRefisTimerEnabled(true)  
      setTimer(`${localStorage.getItem('t_d_d').length > 1 ? localStorage.getItem('t_d_d')+':00' : '0'+localStorage.getItem('t_d_d')+':00'}  `)  
      if(props.handleclickDepositStatusOff !== undefined) {
        props.handleclickDepositStatusOff()
      } 

    }) 

  return (
    <>
    {refisTimerEnabled &&
      <>
        <Modal show={props.show} onHide={props.handleClose} className='unsufficient-balance-topup' backdrop='static' keyboard='false' >
        
        
            <Modal.Header>
            <div className='timer'><img src='/images/dashboard/topup/tabler_alarm.svg' alt='blue alarm' draggable='false' /><span>{timer}</span></div> 
            <figure>
                <img src='/images/dashboard/topup/Group 318.svg' alt='wallet containing fiat money and coins' draggable='false' /> 
            </figure>
            <div className='insufficient-balance'>
                Insufficient  Balance 
            </div>
            <div className='insufficient-balance-deposit-120-dollar'>
                It appears that your current balance is insufficient to order a card. To proceed, please deposit at least <span>${localStorage.getItem('m_f_d')}</span>.
            </div>
        </Modal.Header> 
        
        <Formik
                            initialValues={accountType === 'Blockchain' ? 
                              {
                              topup_amount: '',
                              hash_trx: '',
                              uid: null
                              } :
                              {
                              topup_amount: '',
                              uid: '', 
                              hash_trx: null
                              }}
                            validateOnChange={ true }
                            validateOnBlur={ true } 
                            validationSchema={
                              accountType === 'Blockchain' ?
                              YUP.object({
                                  topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid topu up amount</span>}).test('normal deposit', `Please enter at least $${localStorage.getItem('m_f_d')}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_f_d'))),  
                                  hash_trx: YUP.string().required('Required!') 
                              }) 
                              :
                              YUP.object({
                                  topup_amount: YUP.string().required('Required!').matches(/^[0-9\.]+$/, { message: <span>Please enter a valid topu up amount</span>}).test('normal deposit', `Please enter at least $${localStorage.getItem('m_f_d')}`,  (value, context) =>(parseInt(value))>=parseFloat(localStorage.getItem('m_f_d'))),   
                                  uid: YUP.string().required('Required!').matches(/^[0-9]+$/, { message: <span>Please enter a valid UID</span>}).min(8, 'Please enter a valid UID'), 
                              }) 
                            }

                            onSubmit={async (values, {setSubmitting, resetForm})=>{
                              try {
                                let account_type = `${(accountType === 'Blockchain') ? 'blockchain' : 'ciexAccount'}`   

                                if(account_type === 'blockchain') {
                                  let result = {
                                    api_call: await initialDeposit({hashTransaction: values.hash_trx, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: null}) 
                                  }
                                  const renew = async ()=>{
                                    return await initialDeposit({hashTransaction: values.hash_trx, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: null}) 
                                  }
                                  // If fetch returns 401 
                                  if(result.api_call.status === 401) {
                                    if(await refreshTokenWithAPICallHandler(result, renew) === 'REDIRECT_TO_LOGIN') {
                                      return navigate('/login') 
                                    }

                                  } 
                                  const message = await result.api_call.json() 

                                  if(result.api_call.ok && message.message === 'success') {
                                      navigate('/thankyoufordeposit')
                                      handleMenuActive('')  
                                  }
                                  if(!result.api_call.ok) {
                                    setError(true)
                                  }
                              } else {
                                  let result = {
                                    api_call: await initialDeposit({hashTransaction: null, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: values.uid}) 
                                  }
                                  const renew = async ()=>{
                                    return await initialDeposit({hashTransaction: null, amount: values.topup_amount, address: addressValue, type: account_type, uidAccount: values.uid}) 
                                  }
                                  // If fetch returns 401 
                                  if(result.api_call.status === 401) {
                                    if(await refreshTokenWithAPICallHandler(result, renew) === 'REDIRECT_TO_LOGIN') {
                                      return navigate('/login') 
                                    }

                                  }
                                  const message = await result.api_call.json()

                                  if(result.api_call.ok && message.message === 'success') {
                                      navigate('/thankyoufordeposit') 
                                      handleMenuActive('')                                 
                                  }
                                  if(!result.api_call.ok) {
                                    setError(true)
                                  } 
                              }
                              refTimerIns.current = 0
                              setRequestRefresh(false)
                                        
                            } catch(err) {

                            }
                          }} 
                        >
                            {(formik) => {
                            state = formik
                            return (
                                <Form className='form' onSubmit={formik.handleSubmit}>
                                    <Select handleAccountType={handleAccountType} handleResetForm={handleResetForm} />  
                                    {accountType === 'Blockchain' && <TextInput name='hash_trx' id='hash_trx_wallet-deposit' label='Enter Hash Transaction' 
                                    inputtype='text' onKeyUp={keyUpHandler} /> }
                                    {accountType === 'CIEx Account' && <TextInput name='uid' id='uid' label='Enter CIEx Account UID'        
                                    inputtype='text' onKeyUp={keyUpHandler} click={handleShowInstruction} /> } 
                                    <TextInput name='topup_amount' id='topup_amount' label='Enter amount' 
                                    inputtype='text' onKeyUp={keyUpHandler} currency='$' />  
                                     
                                     
                                    

    
                                    {!loadAdd && <div className='wallet-address-wrapper'>
                                        <div className='use-wallet-address-provided-for-deposit'>Use the wallet address provided below to make the deposit.</div>
                                        <div id='qrcode'></div>
                                        <div>
                                            <div>
                                                <img src='/images/dashboard/topup/tabler_wallet.svg' alt='wallet' draggable='false' /> 
                                                <div className='wallet-address'>
                                                    <span>Wallet address (TRC20)</span>
                                                    <span id='wallet-address-value'>{addressValue}</span>
                                                </div>
                                            </div>
                                            <button className='copy-btn' type='button' onClick={copyAddress}>
                                                <img src='/images/dashboard/topup/tabler_copy.svg' 
                                                alt='2 squares with the highest one its right half clipped' hidden={isCopied}
                                                draggable='false' /> 
                                                <span hidden={!isCopied}>Copied!</span>
                                            </button>
                                        </div>
                                    </div>}
                                    {loadAdd && <div className='d-flex justify-content-center'><Loader /></div>} 
                                    

                                    <div className='check-term-conditions'>
                                      <Field type="checkbox" name="agree_terms_conditions_first_deposit" checked={termsConditions || confirm_TC}  onClick={handleConfirmTermsConditions} />   
                                      <span style={{color: 'var(--black-color)'}}> I Agree to the Terms and Conditions.</span>  
                                    </div> 
                                    
                                     
                                    {accountType === 'Blockchain' && <div className='group-btn'>
                                                                      <Button click={cancelFirstDeposit} id="cancel_first_deposit_hash">Cancel</Button>
                                                                      <Button btnType='submit' id='submit_first_deposit_hash' disabled={(!document.querySelector('input[name="agree_terms_conditions_first_deposit"]')?.checked) || !(formik.dirty && formik.isValid) || formik.isSubmitting } styles='submit-btn'>Submit</Button>
                                                                    </div> 
                                    } 
                                    {accountType === 'CIEx Account' &&  <div className='group-btn'>
                                                                          <Button click={cancelFirstDeposit} id="cancel_first_deposit_uid">Cancel</Button>
                                                                          <Button btnType='submit' id='submit_first_deposit_uid' disabled={(!document.querySelector('input[name="agree_terms_conditions_first_deposit"]')?.checked) || !(formik.dirty && formik.isValid) || formik.isSubmitting } styles='submit-btn'>Submit</Button>
                                                                        </div>   
                                    }   

                                    
                                    
                                </Form>)}}
        </Formik>
        
        
        </Modal>
        <Modal show={showInstruction} onHide={handleCloseInstruction} className='showCiexAccount' id='depositThroughCIExFirstDeposit'> 
        <Modal.Header closeButton>
          <Modal.Title>CIEx account</Modal.Title>
          <img src='/images/dashboard/userguide/Frame 20.svg' alt='6 screenshots showing steps to withdraw crypto on ciex app' />   
          <Carousel fade interval={null} id='withdrawSteps'>
            <Carousel.Item>
              <img src='/images/dashboard/userguide/1.svg' alt='an interface with 5 menus, with a blue line under overview, deposit button and a rectangle with yellow borders around withdraw button ' />
            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/2.svg' alt="an interface with 5 menus, with a blue line under overview, total balance and today\'s PNL values are hidden with asterisks and a rectangle with yellow borders around USDT " />  

            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/3.svg' alt='an interface with 5 menus, with a blue line under overview, at the bottom of it; an area with title withdraw and cancel button and a rectangle with yellow borders around the rectangle with title send via crypto network, below it a rectangle with title send via email/phone/UID' /> 

            </Carousel.Item> 
            <Carousel.Item>
              <img src='/images/dashboard/userguide/4.svg' alt='an interface with main title withdraw, containing a form, into it a label of chain name underneath of it erc-20, trc-20 surrounded by a rectangle with yellow borders , bep-20 and with other fields; address, amount, ... below it other details about the withdraw and a confirm button' />
            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/5.svg' alt='an interface with main title withdraw, containing a form, into it a label of chain name underneath of it erc-20, trc-20, bep-20, a filled adrress field below it a rectangle with yellow borders containing UID:32235592, below it other details about the withdraw and a confirm button' />

            </Carousel.Item>
            <Carousel.Item>
            <img src='/images/dashboard/userguide/6.svg' alt='an interface with main title withdraw, containing a form, within it a field with label send mode and value UID, below it a field with label UID and value 32235592 surrounded by a rectangle with yellow borders, below this field a message starting with this is the UID corresponding to the address..., and in the bottom a confirm button surrounded by a recatngle with yellow borders' /> 

            </Carousel.Item>
          </Carousel> 
        </Modal.Header>
      </Modal>
      </>
    }
    {!refisTimerEnabled && 
        <Modal show={requestRefresh} className='loader-error' backdrop='static' keyboard='false'>
            <Modal.Body className='center'>
                {!error &&<div className='errorMessageContainer'> <figure><img src='/images/dashboard/topup/refresh.svg' width='150' alt='a red arrow following a circular path' draggable='false' /></figure><div>Times out</div><div className='messageError'>It seems your session has timed out. Please refresh the page to continue. If you need assistance, feel free to contact our support team.</div> <Button styles= 'try-again-btn' click={handleCloserequestRefresh}>Refresh</Button></div>}  
                {error && <div>ERROR</div>} 
            </Modal.Body>
        </Modal>
    }
  <ConfirmTermsConditions show={termsConditions} handleShowTermsConditions={handleShowTermsConditions} handleCloseTermsConditions={handleCloseTermsConditions} confirm_TC={()=>setConfirm_TC(true)} handleCloseTermsTC={handleCloseTermsTC} /> 
    </>
  )

}

export default UnsufficientBalance
