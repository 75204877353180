import React  from 'react';
import {  useLocation, useNavigate, useParams } from 'react-router-dom';
import { killToken, logout, refreshToken, saveToken } from '../../../security/AuthService';
import { useToggle } from '../../../context/ToggleContext';
import { useReferral } from '../../../context/ReferralContext';      
import { useGoogleAuthentication } from '../../../context/GoogleAuthenticationContext'; 

import { refreshTokenWithAPICallHandler } from '../../../utils/refresh'; 
function LogoutPage(props){
    const navigate = useNavigate(); 
    const {initToggle} = useToggle()
    const { handleNotShowReferral } = useReferral()
    const { disableGoogleAuthenticationHandler } = useGoogleAuthentication()  

    async function onLogout () {
      try {
        let response = {
          api_call: await logout()
        }
        const renew = async ()=>{
            return await logout()
        }
        // If fetch returns 401 
        if(response.api_call.status === 401) {
          if(await refreshTokenWithAPICallHandler(response, renew) === 'REDIRECT_TO_LOGIN') {
              return navigate('/login') 
          }

        }
        const messageLogout = await response.api_call.json()
        if(response.api_call.ok && messageLogout.message === 'Successfully logged out.') {
          if(killToken().kill) {
            navigate('/login')
            initToggle()
            handleNotShowReferral()
            disableGoogleAuthenticationHandler() 
          }
        }
      } catch (err) {
        navigate('/login')
        killToken()  
      }
       
    }
    
    return(
        <>
            <button  className="dropdown-item ai-icon" onClick={onLogout}>
                <svg
                  id="icon-logout" xmlns="http://www.w3.org/2000/svg"
                  className="text-danger" width={18} height={18} viewBox="0 0 24 24" 
                  fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
                >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
                <span className="ms-2" >Logout </span>
            </button>
        </>
    )
} 
export default LogoutPage