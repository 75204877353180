import React, { useState, useEffect, useRef, useCallback } from 'react' 
import Carousel from 'react-bootstrap/Carousel';
import './CSS/ordercard.css'
import TextInput from '../components/TextInput';
import SelectInput from '../components/SelectInput';
import { Formik, Form, Field } from 'formik'; 
import * as YUP from 'yup'
import Phone from '../components/Phone';
import DateInput from '../components/DateInput';
import { countries, genders } from '../utils/utils'
import { useLoaderData, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'
import Loader from '../jsx/pages/Loader/Loader'  
import { requestCard } from '../security/AuthService';
import Button from '../components/Button'; 
import UnsufficientBalance from '../Modals/UnsufficientBalance';
import SureToContinueTopUp from '../Modals/InsufficientBalance_top_up'; 
import ConfirmTermsConditions from '../Modals/ConfirmTermsConditions'; 
import { refreshTokenWithAPICallHandler } from '../utils/refresh'

const OrderCard = () => {
  const intelinput = useRef(null)
  const error = useRef(null)
  const [index, setIndex] = useState(0)
  const [disablePhone, setDisablePhone] = useState(null)
  const navigate = useNavigate()
  const [isError, setIsError] = useState(false)
  const [show, setShow] = useState(false) 
  const refAllow = useRef(false)
  const [termsConditions1, setTermsConditions1] = useState(false)   
  const termsConditions = useRef(false) 
  const [confirm_TC, setConfirm_TC] = useState(false) 
  const [unsufficientBalanceState, setUnsufficientBalanceState] = useState(false)
  const [sureToContinueTopUpState, setSureToContinueTopUpState] = useState(false)

  const handleShowUnsBalance = useCallback(function() {
    setUnsufficientBalanceState(true)
  }, []) 

  const handleCloseUnsBalance = useCallback(function() {
    setUnsufficientBalanceState(false)
  }, [])

  const handleShowSuretoContinueTopUp = useCallback(function() {
    setSureToContinueTopUpState(true)
  }, [])

  const handleCloseSureToContinueTopUp = useCallback(function() {
    setSureToContinueTopUpState(false) 
  }, [])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);   

  const handleShowTermsConditions = useCallback(function() {
    setTermsConditions1(true) 
  }, [])

  const handleCloseTermsConditions = useCallback(function() {
    setTermsConditions1(false)   
  }, []) 

  const result = useLoaderData() 

  const handleCloseTermsTC = useCallback(function() {
    setConfirm_TC(false)
  }, []) 

  useEffect(()=>{
    if(typeof result === 'number') {
      const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))  
      if(decoded_payload['req'] === 'N' && result < parseFloat(localStorage.getItem('m_b'))) { 
              handleShowUnsBalance()
              refAllow.current = true 
      }
    } else if(typeof result === 'object') {
        refAllow.current = false 
    }
  }, [])

  const handleConfirmTermsConditions = function(e) { 
    if(!termsConditions1) {
      if(confirm_TC) {
        return setConfirm_TC(false) 
      }
    handleShowTermsConditions()    

    } else {
      if(confirm_TC) {
        return setConfirm_TC(false) 
      }
      handleCloseTermsConditions()  
    }
  }
  
  const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p')))
  function handleDisable(value) {
    return setDisablePhone(value)
  }
  let isNext = false
  let maxHeight = useRef(0)
  const currentHeight = useRef(null)
  function cssValue(selector, property) {
    try {
      const element = document.getElementById(selector)
      const cssObj = window.getComputedStyle(document.getElementById(selector), null) 
      let propValue = cssObj.getPropertyValue(property)
      return window.getComputedStyle(document.getElementById(selector), null).getPropertyValue(property)
    } catch(err) {
    }
  }
  useEffect(()=>{
    try {
      if(window.location.pathname === '/ordercard') {
        document.querySelectorAll('.content-carousel-item').forEach(function(cci){ 
            if(parseInt(cci.clientHeight) > maxHeight.current) {
              maxHeight.current = parseInt(cci.clientHeight)
            }
            if(parseInt(maxHeight.current) === 136) {
              currentHeight.current = 170
            }
            else if(parseInt(maxHeight.current) === 180) {
              currentHeight.current = 210 
            } 
            if (window.innerWidth < 400) {
              currentHeight.current = 210
            } 
            if (window.innerWidth < 327) {
              currentHeight.current = 220
            } 
            if(currentHeight.current === null) {
              currentHeight.current = 175
            } 
        })
      }
    } catch(err) {
    }

  }, [index])
  

  useEffect(()=>{
    
    try {
      if(window.location.pathname === '/ordercard') {
        window.addEventListener('resize', ()=>{
        
        try {
          if(window.innerWidth < 640) {
            currentHeight.current = 170
            
            if(parseInt(cssValue("slide_1", "top")) !== 0) {
                if(Math.sign(parseInt(cssValue("slide_1", "top"))) === 1) {
                    document.getElementById('slide_1').style.top = `${currentHeight.current}px`
                }
                else {
                    document.getElementById('slide_1').style.top = `-${currentHeight.current}px`
                }
            }
    
            if(parseInt(cssValue("slide_2", "top")) !== 0) {
              if(Math.sign(parseInt(cssValue("slide_2", "top"))) === 1) {
                  document.getElementById('slide_2').style.top = `${currentHeight.current}px`
              }
              else {
                  document.getElementById('slide_2').style.top = `-${currentHeight.current}px`
              }
          }
    
          if(parseInt(cssValue("slide_3", "top")) !== 0) {
            if(Math.sign(parseInt(cssValue("slide_3", "top"))) === 1) {
                document.getElementById('slide_3').style.top = `${currentHeight.current}px`
            }
            else {
                document.getElementById('slide_3').style.top = `-${currentHeight.current}px`
            }
        }
        
          }
        } catch(err) {
        }
        
        try {
          if(window.innerWidth < 400) {
            currentHeight.current = 210
            
            if(parseInt(cssValue("slide_1", "top")) !== 0) {
                if(Math.sign(parseInt(cssValue("slide_1", "top"))) === 1) {
                    document.getElementById('slide_1').style.top = `${currentHeight.current}px`
                }
                else {
                    document.getElementById('slide_1').style.top = `-${currentHeight.current}px`
                }
            }
    
            if(parseInt(cssValue("slide_2", "top")) !== 0) {
              if(Math.sign(parseInt(cssValue("slide_2", "top"))) === 1) {
                  document.getElementById('slide_2').style.top = `${currentHeight.current}px`
              }
              else {
                  document.getElementById('slide_2').style.top = `-${currentHeight.current}px`
              }
          }
    
          if(parseInt(cssValue("slide_3", "top")) !== 0) {
            if(Math.sign(parseInt(cssValue("slide_3", "top"))) === 1) {
                document.getElementById('slide_3').style.top = `${currentHeight.current}px`
            }
            else {
                document.getElementById('slide_3').style.top = `-${currentHeight.current}px`
            }
        }
        
          }
        } catch(err) {
        }
        
        try {
          if(window.innerWidth < 327) {
            currentHeight.current = 220
            
            if(parseInt(cssValue("slide_1", "top")) !== 0) {
                if(Math.sign(parseInt(cssValue("slide_1", "top"))) === 1) {
                    document.getElementById('slide_1').style.top = `${currentHeight.current}px`
                }
                else {
                    document.getElementById('slide_1').style.top = `-${currentHeight.current}px`
                }
            }
    
            if(parseInt(cssValue("slide_2", "top")) !== 0) {
              if(Math.sign(parseInt(cssValue("slide_2", "top"))) === 1) {
                  document.getElementById('slide_2').style.top = `${currentHeight.current}px`
              }
              else {
                  document.getElementById('slide_2').style.top = `-${currentHeight.current}px`
              }
          }
    
          if(parseInt(cssValue("slide_3", "top")) !== 0) {
            if(Math.sign(parseInt(cssValue("slide_3", "top"))) === 1) {
                document.getElementById('slide_3').style.top = `${currentHeight.current}px`
            }
            else {
                document.getElementById('slide_3').style.top = `-${currentHeight.current}px`
            }
        }
        
          }
        } catch(err) {
        }
        
      })
      }
    } catch(err) {
    }
    
  }, [index])
     
    const handleShowError = useCallback(function handleShowError () {
      setIsError(true)
    }, [])
    const handleCloseError = useCallback(function handleCloseError () {
        setIsError(false)
    }, [])

  function handleBack() {
    setIndex(index - 1)
    isNext = false
    if(index === 0) {
      document.getElementById('active_item_position').style.left = '-97px'

    } else if(index === 1) {
      document.getElementById('active_item_position').style.left = '-97px' 
      document.getElementById('back').style.display = 'none'
      document.getElementById('back1').style.display = 'inline-block'
      document.getElementById('slide_1').style.top = '0'
      document.getElementById('slide_1').style.visibility = 'visible'
      document.getElementById('slide_2').style.top = `${currentHeight.current}px` 

    } else if(index === 2) {
      document.getElementById('active_item_position').style.left = '-72px'
      document.getElementById('slide_2').style.top = '0'
      document.getElementById('slide_2').style.visibility = 'visible'
      document.getElementById('slide_3').style.top = `${currentHeight.current}px` 

    }
  }
  function handleNext() {
    
    setIndex(index + 1)
      isNext = true
      if(index === 0) {
        document.getElementById('active_item_position').style.left = "-72px"
        document.getElementById('back').style.display = 'inline-block'
        document.getElementById('back1').style.display = 'none' 
        document.getElementById('slide_1').style.top = `-${currentHeight.current}px`
        document.getElementById('slide_2').style.top = '0'
        document.getElementById('slide_2').style.visibility = 'visible' 
        document.getElementById('slide_3').style.top = `${currentHeight.current}px`  
      } 
      else if(index === 1) {
        document.getElementById('active_item_position').style.left = '-50px' 
        document.getElementById('slide_2').style.top = `-${currentHeight.current}px`
        document.getElementById('slide_3').style.top = '0'
        document.getElementById('slide_3').style.visibility = 'visible'
      } else if(index === 2) {
        document.getElementById('active_item_position').style.left = '-97px'
        document.getElementById('back').style.display= 'none'
        document.getElementById('next').style.display= 'none' 
        document.getElementById('skip').style.display= 'none' 
        document.querySelector('.text-content-container').style.display = 'none'
        document.querySelector('.bullets').style.display = 'none' 
      }
  } 

  function handleSkip() {
    setIndex(3)
    document.getElementById('back').style.display = 'none'
    document.getElementById('next').style.display = 'none'
    document.getElementById('skip').style.display = 'none'
    document.querySelector('.text-content-container').style.display = 'none'
    document.querySelector('.bullets').style.display = 'none'
  }
  
  
  
  let state
    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }
  return (
    <>
      <>
        <div className='orderCardContainer'>
          <Carousel activeIndex={index} interval={null} >
            <Carousel.Item>
              <img className="d-block w-100" src="/images/dashboard/ordercard/Online shopping (5) 1.gif" alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src="/images/dashboard/ordercard/Wallet (1) 1.gif" alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src="/images/dashboard/ordercard/Payment Information (1) 1.gif" alt="Third slide" />
            </Carousel.Item>
            <Carousel.Item>
              <div className='orderCardWrapper'>
                <figure>
                  <img src='/images/dashboard/ordercard/freepik--credit-card--inject-195.svg' alt='2 cards inside a purple frame where the frontal part is visible for first card and the back part for the other card'/>
                  <figcaption>Order paytaps card</figcaption>
                </figure>
                <div className='orderCardFormWrapper'>
                  <Formik
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            email: decoded_payload['username'],
                            date_birth: '',
                            phone: '',
                            prefered_name: '',
                            nationality: '',
                            country_residence: '',
                            gender: '',
                            billing_address: '',
                            billing_city: '',
                            billing_country: '',
                            billing_zip_code: '',
                            billing_state: '', 
                            delivery_state: '',
                            agree_terms_conditions: false
                        }}

                        validationSchema={YUP.object({
                            first_name: YUP.string().required('Required!').matches(/^[A-Za-z \s]+$/, { message: <span>First Name should contain alphabetical characters</span>}).max(15, 'First Name should not be greater than 15 characters'),
                            last_name: YUP.string().required('Required!').matches(/^[A-Za-z \s]+$/, { message: <span>Last Name should contain alphabetical characters</span>}).max(15, 'Last Name should not be greater than 15 characters'),
                            email: YUP.string().required('Required!').email('Invalid Email!'),
                            date_birth: YUP.string().required('Required!').test('age', 'Please enter your real date of birth',  (value, context) => {return Math.floor((new Date() - new Date(value)) / (1000 * 60 * 60 * 24*365)) >= 18}),    
                            prefered_name: YUP.string().required('Required!').max(15, 'Prefered Name should not be greater than 15 characters'),
                            nationality: YUP.string().required('Required!'),
                            country_residence: YUP.string().required('Required!'),
                            gender: YUP.string().required('Required!'),
                            billing_address: YUP.string().required('Required!').max(40, 'Billing Address should not be greater than 40 characters'),
                            billing_city: YUP.string().required('Required!').max(40, 'Billing City should not be greater than 40 characters'),
                            billing_country: YUP.string().required('Required!'),
                            billing_zip_code: YUP.string().required('Required!').matches(/[0-9]+/, { message: <span>Zip Code should only contain numbers</span>}),
                            delivery_state: YUP.string().required('Required!'),
                        })}

                        onSubmit={ async (values)=>{
                            try {
                              if(isError) {
                                handleCloseError()
                              }
                              
                              handleShow() 
                            
                              let request_card = {
                                api_call: await requestCard({
                                  "first_name": values.first_name,
                                  "last_name": values.last_name,
                                  "preferred_name": values.prefered_name,
                                  "nationality": values.nationality,
                                  "country_code": values.country_residence, 
                                  "gender": values.gender,
                                  "mobile": intelinput.current?.getInstance().getNumber(),
                                  "date_of_birth": values.date_birth,
                                  "billing_address": values.billing_address,
                                  "billing_city": values.billing_city, 
                                  "billing_country": values.billing_country,
                                  "billing_zip_code": values.billing_zip_code,
                                  "billing_state": 'AE',
                                  "delivery_state": values.delivery_state,
                                  "card_type": "2",
                                  "email_address": values.email
                                })
                              }
                              const renewRCA = async ()=>{
                                  return await requestCard({
                                    "first_name": values.first_name,
                                    "last_name": values.last_name,
                                    "preferred_name": values.prefered_name,
                                    "nationality": values.nationality,
                                    "country_code": values.country_residence, 
                                    "gender": values.gender,
                                    "mobile": intelinput.current?.getInstance().getNumber(),
                                    "date_of_birth": values.date_birth,
                                    "billing_address": values.billing_address,
                                    "billing_city": values.billing_city, 
                                    "billing_country": values.billing_country,
                                    "billing_zip_code": values.billing_zip_code,
                                    "billing_state": 'AE',
                                    "delivery_state": values.delivery_state,
                                    "card_type": "2",
                                    "email_address": values.email
                                  })
                              } 
                              // If fetch returns 401
                               if(request_card.api_call.status === 401) {
                                  if(await refreshTokenWithAPICallHandler(request_card, renewRCA) === 'REDIRECT_TO_LOGIN') {
                                      return navigate('/login')  
                                  }
                               }
                              const message = await request_card.api_call.json() 

                              if(request_card.api_call.ok && message.message === 'Card request created successfully') {
                                navigate('/thankyouforcardorder')
                              }
                              if(!request_card.api_call.ok) {
                                error.current = message.message
                                handleShowError()
                              }
                            } catch (err) {
                            }
                        }}
                        >
                        {(formik) => {
                        state = formik
                        return (
                            <Form className='form' onSubmit={formik.handleSubmit}>
                              <div className='formOrderCardRow'>
                                <TextInput name='first_name' label='First Name *' id='firstname' placeholder='Your First Name' onKeyUp={keyUpHandler} />
                                <TextInput name='last_name' label='Last Name *' id='lastname' placeholder='Your Last Name' onKeyUp={keyUpHandler} /> 
                              </div>
                              <div className='formOrderCardRow'>
                                <TextInput name='email' label='Email Address *' id='emailaddress' inputtype='email' placeholder='Email Address' onKeyUp={keyUpHandler} />
                                <DateInput name='date_birth' label='Date of Birth *' id='dateofbirth' /> 
                              </div>
                              <Phone intel={intelinput} name='phone' id='phone' label='Phone Number *' handleDisable={handleDisable} />
                              <div className='formOrderCardRow'>
                                <TextInput name='prefered_name' label='Prefered Name *' id='preferedname' placeholder='Your Prefered Name' onKeyUp={keyUpHandler} />
                                <SelectInput options={countries} name='nationality' label='Nationality *' id='nationality' firstOption='Select Your Nationality' valueSelect={formik} />
                              </div>
                              <div className='formOrderCardRow'>
                                <SelectInput options={countries} name='country_residence' label='Country of residence *' id='countryofresidence' firstOption='Select Your Country of Residence' />
                                <SelectInput options={genders} name='gender' label='Gender *' id='gender' firstOption='Select Your Gender' />
                              </div>
                              <div className='formOrderCardRow'>
                                <TextInput name='billing_address' label='Billing Address *' id='billingaddress' placeholder='Your Billing Address' onKeyUp={keyUpHandler} />
                                <TextInput name='billing_city' label='Billing City *' id='billingcity' placeholder='Your Billing City' onKeyUp={keyUpHandler} />  
                              </div>
                              <div className='formOrderCardRow'>
                                <SelectInput options={countries} name='billing_country' label='Billing Country *' id='billingcountry' firstOption='Select Your billing Country' />
                                <TextInput name='billing_zip_code' label='Billing Zip Code *' id='billingzipcode' placeholder='Your Billing Zip Code' onKeyUp={keyUpHandler} />                                                          
                              </div>
                              <div className='formOrderCardRow'>
                                <TextInput name='billing_state' label='Billing State' id='billingstate' placeholder='Your Billing State' onKeyUp={keyUpHandler}  />
                                <TextInput name='delivery_state' label='Delivery State *' id='deliverystate' placeholder='Your Delivery State' onKeyUp={keyUpHandler} />     
                              </div>
                              <div className='check-term-conditions'>
                                <Field type="checkbox" name="agree_terms_conditions" checked={termsConditions1 || confirm_TC} ref={termsConditions} onClick={handleConfirmTermsConditions} />  
                                <span> I Agree to the Terms and Conditions.</span>  
                              </div>
                                <button type='submit' id='submitOrderCard' disabled={!disablePhone || (!document.querySelector('input[name="agree_terms_conditions"]').checked) || !(formik.dirty && formik.isValid) || formik.isSubmitting}> Order Now </button>    
                            </Form>
                        )}}
                  </Formik>
                </div>
              </div>
            
            </Carousel.Item>
            
          </Carousel>
          <div className="bullets">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span id="active_item_position"></span>
          </div>
          <div className="text-content-container">
              <div className="content-carousel-item" id='slide_1'>
                  <p className="content-title">FREE TO GET NO MONTHLY FEE</p>
                  <p className="content-sub-title">Simplify Your Payments with Your Virtual PAYTAPS CARD</p>
              </div>
              <div className="content-carousel-item" id='slide_2'>
                  <p className="content-title">Compatible with mobile wallets </p>
                  <p className="content-sub-title">Connect It to Your Digital Wallet, Tap & Pay for Online and in-Store Transactions</p>
              </div>
              <div className="content-carousel-item" id='slide_3'>
                  <p className="content-title">SPEND CRYPTO</p>
                  <p className="content-sub-title">Use Crypto to pay online or at merchants world wide</p>
              </div>
          </div>
          <div className="controllers">
            <button type='button' id="back" onClick={handleBack}>Back</button>
            <button type='button' id="back1">Back</button>
            <div className="controllers-next-skip">
                <button type='button' id="skip" onClick={handleSkip}>Skip</button>
                <button type='button' id="next" onClick={handleNext}>Next</button>
            </div>
          </div>
        </div>

      </>
      <>
        <Modal show={show} className='loader-error' backdrop='static' keyboard='false'>
          <Modal.Body className='center'>
            {!isError && <Loader />}
            {isError && <div className='errorMessageContainer'> <figure><img src='/images/registration/tabler_alert-circle.svg' width='150' /></figure><div>Error</div><div className='messageError'>{error.current}</div> <Button styles= 'try-again-btn' click={handleClose}>Try Again!</Button></div>}
          </Modal.Body>
        </Modal>
      </>

      <>
        <UnsufficientBalance 
          show={unsufficientBalanceState} 
          handleClose={handleCloseUnsBalance} 
          handleShowSuretoContinueTopUp={handleShowSuretoContinueTopUp} 
          a={refAllow.current} 
        />  
        <SureToContinueTopUp 
          show={sureToContinueTopUpState} 
          handleClose={handleCloseSureToContinueTopUp}  
        />
        <ConfirmTermsConditions 
          show={termsConditions1} 
          handleShowTermsConditions={handleShowTermsConditions} 
          handleCloseTermsConditions={handleCloseTermsConditions} 
          confirm_TC={()=>setConfirm_TC(true)} 
        />                                          
      </>
    </>
  )

}

export default OrderCard