import React, {useEffect} from 'react'
import './CSS/cardfeatures.css'

const Cardfeatures = () => {
  return (
    <section id='card-features'>
        <div className='card-features-container'>
            <div className='card-features-title'>CARD FEATURES</div>
            <h2 className='card-features-subtitle'>Why Choose PAYTAPS Card?</h2> 
            <div className='card-features-free-to-acquire'>
                
                <div className='card-features-free-to-acquire-text-content'>
                    <p className='card-features-free-to-acquire-text-content-free-to-acquire'>Free to Acquire</p>
                    <p className='card-features-free-to-acquire-text-content-no-initial-cost'>No initial cost for the card.</p>
                </div>
                <img src='/images/landingpage/body/Frame 24 (5).png' alt='dashboard on mobile phone' className='web-phone-img' draggable='false' />
                <img src='/images/landingpage/body/Mockup-iphone-paytapss 1 (9).png' alt='dashboard on mobile phone' className='mobile-phone-img' draggable='false' />                               

            </div>

            <div className='no-charges-low-top-up-fee-container'>
                <div className='no-charges-wrapper'>
                    <img src='/images/landingpage/body/0-monthly-fees 1.png' alt='no monthly charges' draggable='false' />
                    <div>
                        <div className='no-charges-wrapper-title'>No Monthly Charges </div>
                        <div className='no-charges-wrapper-subtitle'>Enjoy the benefits without any monthly fees.</div>
                    </div> 
                </div>
                <div className='low-top-up-fee-wrapper'>
                    <div>
                        <div className='no-charges-wrapper-title'>Low Top-Up Fee:</div>
                        <div className='no-charges-wrapper-subtitle'>Only 3% for top-ups.*</div> 
                    </div>
                    <img src='/images/landingpage/body/top_up_funds 1 (1).png' alt='low top up fee' draggable='false' />
                    <div className='topupAmount4PercentCardFeatures'>4% for Top-Up Amount {`<`} 100$.</div>               
                </div>
            </div>

            <div className='mobile-wallet-compatibility-worldwide-accessible-container'>
                <div className='mobile-wallet-compatibility-wrapper'>
                    <img src='/images/landingpage/body/mobile wallet.png' alt='mobile wallet compatibility' draggable='false' />
                    <div className='mobile-wallet-compatibility-wrapper-text-content'>
                        <div className='mobile-wallet-compatibility-wrapper-title'>Mobile Wallet Compatibility</div>
                        <div className='mobile-wallet-compatibility-wrapper-subtitle'>Works with Apple Pay, Samsung Pay, and Google  Pay.</div>
                    </div>

                </div>
                <div className='worldwide-accessible-wrapper'>
                    <img src='/images/landingpage/body/worldwide.png' alt='worldwide accessible' draggable='false' /> 
                    <div>
                        <div className='worldwide-accessible-wrapper-title'>Worldwide Accessibility</div>
                        <div className='worldwide-accessible-wrapper-subtitle'>Use your card globally.</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )

}

export default Cardfeatures 