// export const API_URL = import.meta.env.API_URL;
// export const API_HOST = import.meta.env.API_HOST;
export function API_URL() {
   // return import.meta.env.API_HOST;
  //return process.env.REACT_APP_API_HOST;
   return process.env.REACT_APP_API_URL || "https://api.develop.paytaps.com/api/"
 }

export function API_HOST() {
   // return import.meta.env.API_HOST;
  //return process.env.REACT_APP_API_HOST;
  return process.env.REACT_APP_API_HOST || "https://api.develop.paytaps.com/"
}
