import React, { useState, useEffect } from "react";                                                                                                                                                                                                                                                                                                                                                                                               

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from './Logout';
/// Image
import profile from "../../../images/profile/17.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import { useActive } from "../../../context/ActiveContext";

import ResetPassword from "./ResetPassword"; 

import { useReferral } from "../../../context/ReferralContext";  
const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
	const { showReferral } = useReferral()  
  var path = window.location.pathname.split("/");
	const {state, setState} = useActive()

	
  return (
    <div className="header">
		<div className="header-content">
			<nav className="navbar navbar-expand">
				<div className="collapse navbar-collapse justify-content-between">

					<ul className="navbar-nav header-right ml-auto">
						
						<Dropdown as="li" className="nav-item header-profile ">
							<Dropdown.Toggle
							  as="a"
							  to="#"
							  variant=""
							  className="nav-link i-false c-pointer"
							>
								<img src="./images/dashboard/dashboard/User-60.svg" width={20} alt="blue user icon" draggable='false' /> 
							</Dropdown.Toggle>
							<Dropdown.Menu align="end" className="mt-2">
								{showReferral && <Link to="/profile" className="dropdown-item ai-icon" onClick={()=> setState({active : 'Profile'})}>
									<svg
									id="icon-user1"
									xmlns="http://www.w3.org/2000/svg"
									className="text-primary"
									width={18}
									height={18}
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
									>
									<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
									<circle cx={12} cy={7} r={4} />
									</svg>
									<span className="ms-2">Profile </span>
								</Link>
								}
							  <ResetPassword /> 
							  <LogoutPage />
							</Dropdown.Menu>
						</Dropdown>
					</ul>
				</div>
			</nav>
		</div>
    </div>
  );
};

export default Header;
