import React, { useState, useEffect, useRef, useCallback } from 'react'
import './CSS/mycard.css'
import { FigureCaption } from 'react-bootstrap'
import BalanceDataDetail from '../components/BalanceDataDetail'
import Button from '../components/Button'
import { useToggle } from '../context/ToggleContext'
import TransactionsVisulizer from '../components/TransactionsVisulizer'
import Topup from '../Modals/Topup'
import InsufficientBalance_top_up from '../Modals/InsufficientBalance_top_up' 
import ShowDetails from '../Modals/ShowDetails' 
import VerifyCardInfo from '../Modals/VerifyCardInfo'
import { useNavigate } from 'react-router-dom'  
import { userBalance, 
        getCardBalance, 
        getCardTopupHistory, 
        convertToUSDT, 
        getCardTransactions, 
} from '../security/AuthService'     
import Loader from '../jsx/pages/Loader/Loader'  
import TopUpVisualizer from '../components/TopUpVisualizer' 
import ApproveCard from '../Modals/ApproveCard' 
import TopupSuccess from '../Modals/TopupSuccess' 
import moment from 'moment';   
import { useReferral } from '../context/ReferralContext'
import { refreshTokenWithAPICallHandler } from '../utils/refresh'
import { walletRedirectButton } from '../utils/utils' 

const MyCard = () => {
    const { showReferral, handleShowReferral } = useReferral()
    const { toggle } = useToggle()
    const navigate = useNavigate() 
    const activeFilterPrevTrx = useRef(null)  
    const activeFilterTopUp = useRef(null)   
    const [show, setShow] = useState(false)
    const [balanceCard, setBalanceCard] = useState('')
    const [balanceWallet, setBalanceWallet] = useState('') 
    const [previousTrx, setPreviousTrx] = useState({data: []}) 
    const [topupHistory, setTopupHistory] = useState({data: []}) 
    const [filteredPreviousTrx, setFilteredPreviousTrx] = useState({data: []}) 
    const [filteredTopupHistory, setFilteredTopupHistory] = useState({data: []})   
    const [showdetails, setShowDetails] = useState(false)
    const [showcarddetails, setShowcardDetails] = useState(false) 
    const [showInsufficientTopupBalance, setShowInsufficientTopupBalance] = useState(false)  
    const [error, setError] = useState('') 
    const [cardHolderDataLoadingState, setCardHolderDataLoadingState] = useState(true)  
    const [showApproveCard, setShowApproveCard] = useState(false) 
    const [balanceUsd, setBalanceUsd] = useState(0)   
    const [showSuccess, setShowSuccess] = useState(false) 
    const [show1, setShow1] = useState(false)                                                        
    const refAllow = useRef(false) 

    const handleClose = () => {setShow(false)} 
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const handleCloseDetails = () => setShowDetails(false);
    const handleShowDetails = () => setShowDetails(true);  
    const handleClosecardDetails = () => setShowcardDetails(false);
    const handleShowcardDetails = () => setShowcardDetails(true);
    const handleCloseInsufTopupBalance = () => setShowInsufficientTopupBalance(false); 
    const handleShowInsufTopupBalance = () => setShowInsufficientTopupBalance(true);                                        
    const handleRefAllowTrue = ()=>  refAllow.current = true  
    const handleRefAllowFalse = ()=>  refAllow.current = false 
    
    const handleShowApproveCard = useCallback(function () {
        setShowApproveCard(true)
    }, [])
    const handleCloseApproveCard = useCallback(function () {
        setShowApproveCard(false) 
    }, [])  
     
    const loading = useRef(false)

    const handleCloseTopupSuccess = useCallback(function () {
        setShowSuccess(false) 
    }, [])
    const handleShowTopupSuccess = useCallback(function () {
        setShowSuccess(true)
    }, [])  
    const [activeRerender, setActiveRerender] = useState(false) 
     
    useEffect(()=>{
        let ignore = false;
        getAllCardHolderDetails(ignore)  

    }, [activeRerender])                       

    const updateBalance = useCallback(function (newBalance) {
        setBalanceWallet(newBalance) 
        setActiveRerender(activeRerender => !activeRerender)  
    }, [activeRerender])           


    function activeFilterPrevTrxHandler (e) {

        if((e.target.innerHTML !== 'Current Month' && e.target.innerHTML !== 'Current Week') && (e.target.innerHTML !== 'Today')) {
            activeFilterPrevTrx.current = null 
        } else {
            activeFilterPrevTrx.current = e.target.innerHTML  
        }  
        handleFilterPrevTrx()

    }
    function activeFilterTopUpHandler (e) {

        if((e.target.innerHTML !== 'Current Month' && e.target.innerHTML !== 'Current Week') && (e.target.innerHTML !== 'Today')) {
            activeFilterTopUp.current = null 
        } else {
            activeFilterTopUp.current = e.target.innerHTML  
        }
        handleFilterTopupHistory() 

    } 

    function topupClickHandler () {
        handleShow()
    }
    
    async function getAllCardHolderDetails(ignore) {
        try {
            setCardHolderDataLoadingState(true)
            if(!showReferral) {
                handleShowReferral() 
            } 
             let result_balance_wallet = {
                api_call: await userBalance()
             }
            const renewB = async ()=>{
                return await userBalance()
            }
            // If fetch returns 401
            if(result_balance_wallet.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(result_balance_wallet, renewB) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const wallet_balance = await result_balance_wallet.api_call.json()             
            
            let result_card_balance = {
                api_call: await getCardBalance()
            }
            const renewCAB = async ()=>{
                return await getCardBalance()
            }
            // If fetch returns 401
            if(result_card_balance.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(result_card_balance, renewCAB) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const card_balance = await result_card_balance.api_call.json() 

            let result_card_trx = {
                api_call: await getCardTransactions()
            }
            const renewCAT = async ()=>{
                return await getCardTransactions()
            }
            // If fetch returns 401
            if(result_card_trx.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(result_card_trx, renewCAT) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const card_previous_trx = await result_card_trx.api_call.json() 


            let result_card_topup_history = {
                api_call: await getCardTopupHistory()
            }
            const renewCTH = async ()=>{
                return await getCardTopupHistory()
            }
            // If fetch returns 401
            if(result_card_topup_history.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(result_card_topup_history, renewCTH) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const card_topup_history = await result_card_topup_history.api_call.json() 

             
            let convert = {
                api_call: await convertToUSDT({
                    amount: wallet_balance['data'][0]['balance'] 
                })
            }
            const renewCTU = async ()=>{
                return await convertToUSDT({
                    amount: wallet_balance['data'][0]['balance'] 
                })
            }
            // If fetch returns 401
            if(convert.api_call.status === 401) {
                if(await refreshTokenWithAPICallHandler(convert, renewCTU) === 'REDIRECT_TO_LOGIN') {
                    return navigate('/login')  
                }
            }
            const convert_result = await convert.api_call.json() 
             
            if(!ignore) {
                if(result_balance_wallet.api_call.ok && result_card_balance.api_call.ok && result_card_trx.api_call.ok && convert.api_call.ok && result_card_topup_history.api_call.ok) {                        
                
                    
                    setBalanceWallet(wallet_balance['data'][0]['balance']) 
                    setBalanceCard(card_balance['data']['balance'])
                         
                    setPreviousTrx({...previousTrx, data: card_previous_trx['data'].reverse()}); setFilteredPreviousTrx({...filteredPreviousTrx, data: card_previous_trx['data']})    
                    setTopupHistory({...topupHistory, data: card_topup_history['data']}); 
                    setFilteredTopupHistory({...filteredTopupHistory, data: card_topup_history['data']}) 
                       
                    setCardHolderDataLoadingState(false) 
                       
                    setBalanceUsd(convert_result['data']['result'])  
                } else {
                    setError('Error') 
                    setCardHolderDataLoadingState(false) 
                    loading.current = false 
                }  
            }  
        } catch(err) {
            setError(err) 
        } finally {
            setCardHolderDataLoadingState(false) 
            loading.current = false 
        }
          
    } 
    
    function handleFilterPrevTrx() {
        
            if(activeFilterPrevTrx.current==='Current Month') {
                const filteredData = previousTrx["data"].filter((trx)=> (((moment().month() + 1) === moment(trx['dateOfTransaction']).month() + 1) && ((moment(trx['dateOfTransaction']).year()) === moment().year() )))    
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData})
                   
            } else if(activeFilterPrevTrx.current === 'Current Week') {
                const filteredData = previousTrx['data'].filter((trx)=> ((moment(trx['dateOfTransaction']).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx['dateOfTransaction']).valueOf() <= moment().endOf('week').valueOf())))     
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData}) 
            } else if(activeFilterPrevTrx.current === 'Today') {
                const filteredData = previousTrx['data'].filter((trx)=> ((moment(trx['dateOfTransaction']).valueOf() >= moment().startOf('day')) && (moment(trx['dateOfTransaction']).valueOf() <= moment().endOf('day'))))   
                return  setFilteredPreviousTrx({...filteredPreviousTrx, data: filteredData})  
            } else {

                return setFilteredPreviousTrx({data: previousTrx['data']})                                                                                                                                                                 
            }  
        
    } 

    function handleFilterTopupHistory() {
        
        if(activeFilterTopUp.current==='Current Month') {
            const filteredData = topupHistory["data"].filter((trx)=> (((moment().month() + 1) === moment(trx?.createdAt?.date).month() + 1) && ((moment(trx?.createdAt?.date).year()) === moment().year() )))    
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})
               
        } else if(activeFilterTopUp.current === 'Current Week') {
            const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf()>= moment().startOf('week').valueOf()) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('week').valueOf())))     
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData}) 
        } else if(activeFilterTopUp.current === 'Today') {
            const filteredData = topupHistory['data'].filter((trx)=> ((moment(trx?.createdAt?.date).valueOf() >= moment().startOf('day')) && (moment(trx?.createdAt?.date).valueOf() <= moment().endOf('day'))))  
            return  setFilteredTopupHistory({...filteredTopupHistory, data: filteredData})  
        } else {
            
            return setFilteredTopupHistory({data: topupHistory['data']})  
        } 
    
    }  
    
return (
    <>
        {((!cardHolderDataLoadingState) && !error) && <div className='mycard-container'>
            <section className='balance-card-container'>
                <div className='mycard-title'>My Paytaps card</div>
                <div className='mypaytaps-container'>
                    <div className='main-balance-wrapper'>
                        <figure className='main-balance-figure-wrapper'>
                            <img src='/images/dashboard/mycard/card.svg' alt='card' draggable='false' />
                            <FigureCaption>Main Balance</FigureCaption>
                        </figure>
                        <div className='balance-details-wrapper'>
                            
                            <BalanceDataDetail imagesrc='/images/dashboard/mycard/tabler_credit-card.svg' 
                                label='Card balance' value={`$${parseFloat(balanceCard).toFixed(2)}`} altText='card balance' 
                            />
                            <BalanceDataDetail imagesrc='/images/dashboard/mycard/mingcute_coin-2-line.svg' 
                                label='Wallet balance' value={`$${parseFloat(balanceWallet).toFixed(2)}`} altText='wallet'  
                            />
                            <Button styles='topup-btn-styles' click={topupClickHandler}>+Top Up</Button>
                            
                        </div>
                        
                    </div>
                    <div className={`card-wrapper ${toggle ? 'align-items-center' : ''}`} >
                        <figure>
                            <img 
                                src='/images/dashboard/mycard/cards-new-visa1.png' 
                                alt='A purple area with a linear gradient from lightest to darkest towards the top right, in the upper left corner a full Paytabs logo. At the bottom a large Paytabs text with low opacity shifted a little downwards and in the lower right corner a white VISA text'  
                                draggable='false'
                                loading='eager' 
                            />                              
                            <span className='card-holder-name'>XXXXX XXXXX</span>

                            <span className='card-number'>XXXX XXXX XXXX XXXX</span> 

                            <div className='card-validity'>
                                <span>VALID THRU</span>
                                <span>XX/XX</span> 
                            </div>
                            <Button imagesrc='/images/dashboard/mycard/checked.svg' styles='approvetrx-btn-styles' click={handleShowApproveCard}>Approve Transaction</Button>
                        </figure>
                        <div className='card-config-wrapper'>
                            <Button imagesrc='/images/dashboard/mycard/uil_setting.svg' styles='setting-btn-styles' click={walletRedirectButton}>Add to Wallet</Button> 
                            <Button imagesrc='/images/dashboard/mycard/tabler_eye.svg' styles='showdetails-btn-styles' click={handleShowDetails}> Show details</Button>
                        </div>
                    </div>
                </div>
            </section>

            
            <section className='history-data-container'>
                <div className='previous-transactions-wrapper'>
                    <div className='previous-transactions-wrapper-header myCardPreviousTrxHeader'>
                        <div className='previous-transactions-wrapper-header-text-content'>
                            <span>Previous Transactions</span>
                            {(previousTrx['data'].length > 0) && 
                            <>
                            {(activeFilterPrevTrx.current === 'Current Month') && <span>Current month's transactions</span>}
                            {(activeFilterPrevTrx.current === 'Current Week') && <span>Current Week's transactions</span>}
                            {(activeFilterPrevTrx.current === 'Today') && <span>Today's transactions</span>}
                            {(activeFilterPrevTrx.current === null) && <span>All previous transactions</span>}                      
                            </>}
                        </div>
                        {(previousTrx['data'].length > 0) && <div className='previous-transactions-wrapper-header-filter'>
                            <button className={`${activeFilterPrevTrx.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler} >Current Month</button>
                            <button className={`${activeFilterPrevTrx.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Current Week</button>
                            <button className={`${activeFilterPrevTrx.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterPrevTrxHandler}>Today</button>                                                                            
                            {((activeFilterPrevTrx.current === 'Current Month' || activeFilterPrevTrx.current === 'Current Week') || activeFilterPrevTrx.current === 'Today') && <button className='clearFilter' onClick={activeFilterPrevTrxHandler}><span>X</span> <span>Clear Filter</span></button>}         
                        </div>}
                    </div>
                    
                <TransactionsVisulizer data={filteredPreviousTrx['data']} parentFilter={activeFilterPrevTrx.current} />

                </div>

                <div className='top-up-history-wrapper'>
                    <div className='previous-transactions-wrapper-header myCardTopupHistoryHeader'>
                        <div className='previous-transactions-wrapper-header-text-content'>
                            <span>Top-up History </span>
                            {(topupHistory['data'].length > 0) && 
                            <>
                            {(activeFilterTopUp.current === 'Current Month') && <span>Current month's transactions</span>}
                            {(activeFilterTopUp.current === 'Current Week') && <span>Current Week's transactions</span>}
                            {(activeFilterTopUp.current === 'Today') && <span>Today's transactions</span>}
                            {(activeFilterTopUp.current === null) && <span>All Top-up history</span>}                   
                            </>}
                        </div>
                        {(topupHistory['data'].length > 0) &&<div className='previous-transactions-wrapper-header-filter'>
                            <button className={`${activeFilterTopUp.current === 'Current Month' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler} >Current Month</button>
                            <button className={`${activeFilterTopUp.current === 'Current Week' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Current Week</button>
                            <button className={`${activeFilterTopUp.current === 'Today' ? 'active-filter' : ''}`} onClick={activeFilterTopUpHandler}>Today</button>                                                                                    
                            {((activeFilterTopUp.current === 'Current Month' ||activeFilterTopUp.current === 'Current Week') || activeFilterTopUp.current === 'Today') && <button className='clearFilter' onClick={activeFilterTopUpHandler}><span>X</span> <span>Clear Filter</span></button>}                                             
                        </div>} 
                    </div>
                    
                    <TopUpVisualizer data={filteredTopupHistory['data']} topup={true} />      

                </div>
                
            </section>

        </div>}
        {((cardHolderDataLoadingState) && !error) && <div className='waitLoadingProfileData'><div>Please wait...</div><Loader /></div>}
    
        <Topup 
            show={show} 
            handleClose={handleClose} 
            handleShowConfirmation={handleShow1} 
            handleShowInsufTopupBalance={handleShowInsufTopupBalance} 
            updateBalance={updateBalance} 
            balanceUsd={balanceUsd}  
            balanceUSDT={balanceWallet} 
            handleShowTopupSuccess={handleShowTopupSuccess} 
        />          
        <InsufficientBalance_top_up 
            show={showInsufficientTopupBalance} 
            handleCloseInsufTopupBalance={handleCloseInsufTopupBalance} 
            handleShowInsufTopupBalance={handleShowInsufTopupBalance} 
        />
        <TopupSuccess show={showSuccess} handleClose={handleCloseTopupSuccess} />                                                                                                                                                                                                                   
        <ShowDetails show={showcarddetails}  handleClosecardDetails={handleClosecardDetails} />   
        <VerifyCardInfo 
            show={showdetails} 
            handleClose={handleCloseDetails} 
            a={refAllow.current} 
            handleRefAllowTrue={handleRefAllowTrue} 
            handleRefAllowFalse={handleRefAllowFalse} 
            handleClosecardDetails={handleClosecardDetails} 
            handleShowcardDetails={handleShowcardDetails} 
        />   
        <ApproveCard show={showApproveCard} handleClose={handleCloseApproveCard} />     
    </> 
  )
}

export default MyCard
