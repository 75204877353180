import React, { useState, useRef, useCallback, useEffect } from 'react'  
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as YUP from 'yup'
import Modal from 'react-bootstrap/Modal'
import TextInput from '../components/TextInput'
import PasswordInput from '../components/PasswordInput'
import Loader from '../../src/jsx/pages/Loader/Loader'
import './login.css'
import { verifyEmail, securedLogin } from '../security/AuthService'                                                                                                                                                                                                                                                                                 
import { useActive } from '../context/ActiveContext' 
import Button from '../components/Button'
import SEO from '../components/SEO'  
import Verification from '../Modals/Verification'  
import CheckEmail from '../Modals/CheckEmail' 
import { formatError } from '../utils/utils'

const Login = () => {   
    const error = useRef(null)
    const [show, setShow] = useState(false)
    const navigate = useNavigate()
    const [isError, setIsError] = useState(false)
    const {handleMenuActive} = useActive()                              
    const [hasCheckedEmail, setHasCheckedEmail] = useState(false)  
    const [checkEmailVerificationTitle, setCheckEmailVerificationTitle] = useState('Check your email')
    const [loginVerification, setLoginVerification] = useState({
        showVerification: false,
        verificationTitle: 'Enter your security verification',
        verificationDescription: 'A verification code has been sent to your email. Enter the code below to continue.',
        email: '',
        password: '',
        authenticationType: '2fa'  
    })
    const location = useLocation() 
    useEffect(()=>{  
        if(location?.state?.toVerifyAccount) {
            handleShowHasCheckedEmail() 
        }
    }, [])  

    let state 

    const keyUpHandler = (e)=>{
        state.setFieldValue(e.target['name'], e.target.value); 
        state.setFieldTouched(e.target['name'])
    }

    const handleClose = useCallback(() => setShow(false), []);

    const handleShow = useCallback(() => setShow(true), []);

    const handleShowError = useCallback(()=>{
        setIsError(true)
    }, [])

    const handleCloseError = useCallback(()=>{
        setIsError(false)
    }, [])
    const handleShowHasCheckedEmail = useCallback(()=>{
        setHasCheckedEmail(true)
    }, []) 
    const handleNotShowHasCheckedEmail = useCallback(()=>{
        setHasCheckedEmail(false) 
    })
    const closeLoginVerification = useCallback(function () {
        setLoginVerification({
            ...loginVerification,
            showVerification: false
        })
    }, []) 
    let authResponse
    let activationCode =   new URL(window.location.href).searchParams.get('token')

    return (
        <>
        <SEO title={'PayTaps - Your Crypto Payment Solution'} 
            description={'PayTaps Crypto Visa Card: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
            ogTitle={'PayTaps Crypto Visa Card – Spend Crypto With A Simple Tap'} 
            ogDescription={'PayTaps Crypto Visa Card: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
            ogUrl={'https://paytaps.com'}  
            twitterTitle={'PayTaps Crypto Visa Card – Spend Crypto With A Simple Tap'} 
            twitterDescription={'PayTaps Crypto Visa Card: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
            canonicalLink={'https://paytaps.com'}    
        /> 
        <div className='login-container'>
            <div className='login-wrapper'>
                <figure className='login'>
                </figure>
                <div className='login-form-container'>
                    <div className='form-welcoming-part'>
                        <Link to='/'>
                            <img src='/images/registration/Group 1.svg' alt='paytabs logo' draggable='false' />
                        </Link>
                        <div id='welcome-to-paytabs'>Welcome to PayTaps</div>
                        <div id='signup-signin-your-account'>Sign in by entering information below</div> 
                    </div>
                    <>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: ''
                                }}

                                validationSchema={YUP.object({
                                    email: YUP.string().required('Required!').email('Invalid Email!'),
                                    password: YUP.string().required('Required!')
                                    
                                })}

                                onSubmit={ async (values, {setSubmitting, resetForm})=>{
                                    
                                    try {
                                        if(isError) {
                                            handleCloseError()
                                        }
                                        
                                        handleShow() 
                                        const response = await securedLogin({email: values.email, password: values.password}) 
                                        authResponse = await response.json()
                                        

                                        if(!response.ok && (authResponse.message === 'Account not verified')) { 
                                              
                                            const post_verify_email = await verifyEmail({token: activationCode}) 
                                            const result_post_verify_email = await post_verify_email.json() 
                                            
                                            sessionStorage.setItem('u_e', values.email)  
                                             

                                            if(!post_verify_email.ok && (result_post_verify_email.message === 'Invalid data'  || result_post_verify_email.message === 'Token invalide.')) { 
                                                setCheckEmailVerificationTitle('Your account is not verified yet')        
                                                handleClose()   
                                                handleShowHasCheckedEmail() 

                                            } else if(post_verify_email.ok && (result_post_verify_email.message === 'Success')) {
                                                const response = await securedLogin({email: values.email, password: values.password}) 
                                                authResponse = await response.json() 
                                                 
                                                if(response.ok && authResponse.message === 'successfully') { 

                                                    if(authResponse?.data?.type === '2fa') {
                                                        handleClose() 
                                                        setLoginVerification({
                                                            ...loginVerification,
                                                            showVerification: true,
                                                            email: values.email,
                                                            password: values.password 
                                                        }) 
                                                        handleCloseError() 
                                                    } 

                                                }
                                            }
                                        }
                                        if(response.ok && authResponse.message === 'successfully') {
                                            if(sessionStorage.getItem('u_e')) {
                                                sessionStorage.clear()
                                            } 

                                            if(authResponse?.data?.type === '2fa') {
                                                handleClose() 
                                                setLoginVerification({
                                                    ...loginVerification,
                                                    showVerification: true,
                                                    email: values.email,
                                                    password: values.password 
                                                }) 
                                                handleCloseError()
                                            } else if(authResponse?.data?.type === 'google') {
                                                handleClose() 
                                                setLoginVerification({
                                                    ...loginVerification,
                                                    showVerification: true,
                                                    verificationTitle: 'Enter Google Authenticator Code',
                                                    verificationDescription: 'Please enter the 6-digit code from your Google Authenticator app to proceed.', 
                                                    email: values.email,  
                                                    password: values.password,
                                                    authenticationType: 'google'  
                                                })  
                                            }
                                               
                                        }
                                        else if(!response.ok && authResponse.error) {
                                            error.current = formatError(authResponse.message)
                                            handleShowError()
                                        }  
                                        
                                    } catch (err) {
                                    }

                                }}
                            >
                                {(formik) => {

                                state = formik
                                
                                return (

                                    <Form className='form' onSubmit={formik.handleSubmit}>

                                        <TextInput inputtype='email' name='email' id='email' label='Email' error={formik.errors.email} touched={formik.touched.email} onKeyUp={keyUpHandler} />

                                        <PasswordInput name='password' id='password' label='Password' error={formik.errors.password} touched={formik.touched.password} onKeyUp={keyUpHandler} />
                                        <Link to='/resetpassword' className='forgotPassword'>Forgot Password</Link>

                                        <button type='submit' id='sign-in' disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}>Sign In</button> 

                                    </Form>)}}
                            </Formik>
                    </>
                    <div className='already-have-account'>
                        Don't have an account?
                        <Link to='/register' style={{
                            fontWeight: '700',
                            color: '#0E0E0E', 
                            textDecoration: 'underline' 
                        }}>  Sign up </Link>
                    </div> 
                </div>
            </div>
        </div> 
    

        <Modal show={show} className='loader-error' backdrop='static' keyboard='false'>
            <Modal.Body className='center'>
                {!isError && <Loader />}
                {isError && <div className='errorMessageContainer'> <figure><img src='/images/registration/Reset password-rafiki.svg' width='300' /></figure><div>Invalid credentials</div><div className='messageError'>The email address or password you entered is incorrect. Please check your details and try again. </div> <Button styles= 'try-again-btn' click={handleClose}>Try Again!</Button></div>}  
            </Modal.Body>
        </Modal>
        <Verification 
            show={loginVerification.showVerification} 
            title={loginVerification.verificationTitle} 
            description={loginVerification.verificationDescription}
            loginEmail={loginVerification.email}
            loginPassword={loginVerification.password}
            handleClose={closeLoginVerification} 
        />    
        <CheckEmail 
            show={hasCheckedEmail} 
            handleClose={handleNotShowHasCheckedEmail} 
            title={checkEmailVerificationTitle} 
        />     
    </>

)
}

export default Login
