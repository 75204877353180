import React, { useEffect } from 'react'             
/// React router dom
import {Routes, Route, Outlet, useNavigate} from 'react-router-dom'                        

import { killToken, getLoginType } from '../security/AuthService' 
import { useGoogleAuthentication } from '../context/GoogleAuthenticationContext' 
import { refreshTokenWithAPICallHandler } from '../utils/refresh' 
import SEO from '../components/SEO' 
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'
import ThemeContext from '../context/ThemeContext';

/// Dashboard
import Home from './components/Dashboard/Home'
import MyWallet from './components/Dashboard/MyWallet'
import Invoices from './components/Dashboard/Invoices'
import CardsCenter from './components/Dashboard/CardsCenter'
import Transactions from './components/Dashboard/Transactions'
import TransactionsDetails from './components/Dashboard/TransactionsDetails'
import Task from './components/Dashboard/Task';

/// App
import AppProfile from './components/AppsMenu/AppProfile/AppProfile'
import PostDetails from './components/AppsMenu/AppProfile/PostDetails'
import Compose from './components/AppsMenu/Email/Compose/Compose'
import Inbox from './components/AppsMenu/Email/Inbox/Inbox'
import Read from './components/AppsMenu/Email/Read/Read'
import Calendar from './components/AppsMenu/Calendar/Calendar'

/// Product List
import ProductGrid from './components/AppsMenu/Shop/ProductGrid/ProductGrid'
import ProductList from './components/AppsMenu/Shop/ProductList/ProductList'
import ProductDetail from './components/AppsMenu/Shop/ProductGrid/ProductDetail'
import Checkout from './components/AppsMenu/Shop/Checkout/Checkout'
import Invoice from './components/AppsMenu/Shop/Invoice/Invoice'
import ProductOrder from './components/AppsMenu/Shop/ProductOrder'
import EcomCustomers from './components/AppsMenu/Shop/Customers/Customers'

/// Charts
import RechartJs from './components/charts/rechart'
import ChartJs from './components/charts/Chartjs'
//import Chartist from './components/charts/chartist'
import SparklineChart from './components/charts/Sparkline'
import ApexChart from './components/charts/apexcharts'

/// Bootstrap
import UiAlert from './components/bootstrap/Alert'
import UiAccordion from './components/bootstrap/Accordion'
import UiBadge from './components/bootstrap/Badge'
import UiButton from './components/bootstrap/Button'
import UiModal from './components/bootstrap/Modal'
import UiButtonGroup from './components/bootstrap/ButtonGroup'
import UiListGroup from './components/bootstrap/ListGroup'
import UiCards from './components/bootstrap/Cards'
import UiCarousel from './components/bootstrap/Carousel'
import UiDropDown from './components/bootstrap/DropDown'
import UiPopOver from './components/bootstrap/PopOver'
import UiProgressBar from './components/bootstrap/ProgressBar'
import UiTab from './components/bootstrap/Tab'
import UiPagination from './components/bootstrap/Pagination'
import UiGrid from './components/bootstrap/Grid'
import UiTypography from './components/bootstrap/Typography'

/// Plugins
import Select2 from './components/PluginsMenu/Select2/Select2'
import Nestable from './components/PluginsMenu/Nestable/Nestable'
import MainNouiSlider from './components/PluginsMenu/NouiSlider/MainNouiSlider'
import MainSweetAlert from './components/PluginsMenu/SweetAlert/SweetAlert'
import Toastr from './components/PluginsMenu/Toastr/Toastr'
import JqvMap from './components/PluginsMenu/JqvMap/JqvMap'
import Lightgallery from './components/PluginsMenu/Lightgallery/Lightgallery'


/// Widget
import Widget from './pages/Widget'

/// Table
import DataTable from './components/table/DataTable'
import BootstrapTable from './components/table/BootstrapTable'
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";


/// Form
//import WizardForm from "./components/Forms/ReduxWizard/Index";
import Element from './components/Forms/Element/Element'
import Wizard from './components/Forms/Wizard/Wizard'
import CkEditor from './components/Forms/CkEditor/CkEditor'
import Pickers from './components/Forms/Pickers/Pickers'
import FormValidation from './components/Forms/FormValidation/FormValidation'

/// Pages
//import Registration from './pages/Registration'
//import Login from './pages/Login'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'
import Todo from './pages/Todo';
import MyCard from '../dashboard/MyCard'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';

import Snowfall from 'react-snowfall' 
import SnowFallParent from '../components/SnowFallParent' 

export default function MainLayout(){
  const navigate = useNavigate() 
  const { hasEnabledGoogleAuthentication, enableGoogleAuthenticationHandler } = useGoogleAuthentication() 
  const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 

  useEffect(()=>{
    if(decoded_payload?.loginType === 'google') {
      enableGoogleAuthenticationHandler() 
    } else {
      getLoginTypeHandler() 
    }
  }, [hasEnabledGoogleAuthentication])  

  async function getLoginTypeHandler () {
    let fetch_login_type = {
      api_call: await getLoginType()
    }
    // If fetch returns 401
    if(fetch_login_type.api_call.status === 401) {
      const renewLOT = async ()=>{
        return await getLoginType()
      } 
      if(await refreshTokenWithAPICallHandler(fetch_login_type, renewLOT) === 'REDIRECT_TO_LOGIN') {
        return navigate('/login')  
      }
    } 
    const result_fetch_login_type = await fetch_login_type.api_call.json()

    if(fetch_login_type.api_call.ok && (result_fetch_login_type.message === 'successfully')) {
      if(result_fetch_login_type?.data?.loginType === 'google') {
        enableGoogleAuthenticationHandler() 
      }
    } 
  }    

    return ( 
      <>
        <ScrollToTop /> 
        <SEO title={'PayTaps - Your Crypto Payment Solution'} 
            description={'PayTaps Crypto Visa Card: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
            ogTitle={'PayTaps Crypto Visa Card – Spend Crypto With A Simple Tap'} 
            ogDescription={'PayTaps Crypto Visa Card: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
            ogUrl={'https://paytaps.com'}  
            twitterTitle={'PayTaps Crypto Visa Card – Spend Crypto With A Simple Tap'} 
            twitterDescription={'PayTaps Crypto Visa Card: Free, secure virtual card. Works with Apple Pay, Samsung Pay, and Google Pay. Spend crypto as fiat globally, hassle-free.'} 
            canonicalLink={'https://paytaps.com'}   
        />
        <div id="main-wrapper" 
            className="show"
        > 
          
          <Nav />
          <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
              <div className="container-fluid">
                <Outlet />                
              </div>
          </div>
          {/* <Footer />       */}
        </div>
      </>
    ) 
  }; 
  