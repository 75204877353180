import React, { useEffect } from 'react' 
import { findRequestCard } from '../security/AuthService'
import { useReferral } from '../context/ReferralContext'
import { useNavigate } from 'react-router-dom'
import { refreshTokenWithAPICallHandler } from '../utils/refresh'


const ThankYouForDeposit = () => {
  const navigate = useNavigate() 
  const { showReferral, handleShowReferral } = useReferral()
  useEffect(()=>{
      const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
      if(decoded_payload['req'] !== 'S') {
      findCardRequest()
      } else {
        if(!showReferral) {
          handleShowReferral() 
        }  
      }
  }, []) 

  async function findCardRequest () { 
      let fetch_card_request = {
          api_call: await findRequestCard() 
      }
      // If fetch returns 401 
      if(fetch_card_request.api_call.status === 401) {
          const renewFCR = async ()=>{
              return await findRequestCard()
          }
          if(await refreshTokenWithAPICallHandler(fetch_card_request, renewFCR) === 'REDIRECT_TO_LOGIN') {
              return navigate('/login')  
          }
      }
      const result_fetch_card_request = await fetch_card_request.api_call.json()   

      if(result_fetch_card_request['data'].length > 0) {
          if(result_fetch_card_request['data'][0]['requesteStatus'] === 'Success') {
              handleShowReferral() 
          }
      }
  } 
  return (
    <>
        <div className='thankYouOrderCardContainer'>
                <div className='thankYouOrderCardWrapper'>
                    <figure>
                        <img 
                            src='/images/dashboard/thankyoufordeposit/Wallet-pana 1.svg' 
                            alt='wallet with crypto coins and a card around it' 
                            draggable='false' 
                        /> 
                    </figure>
                    <div className='thankYouOrderCardContent'>
                        <div className='thankYouOrderCardContentTitle'>Thank you for your Deposit</div>
                        <p className='thankYouOrderCardContentDescription'>Thank you for your Paytaps wallet deposit <br /> 
                        you'll receive an email notification once your deposit is confirmed</p> 
                    </div>
                </div>
        </div>
    </>
  )
}

export default ThankYouForDeposit 
