import React, { useCallback } from 'react'
import './lpfooter.css'
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

const LpFooter = () => {
  const scrollHandler = useCallback(function scrollHandler(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth", block: "start"});
  }, [])


  return (
    <footer>
      <div className='footer-wrapper'>
        <figure>
          <img src='/images/landingpage/footer/Layer 3.svg' alt='paytabs' draggable='false' /> 
        </figure>
        <div className='footer-row-1'>
          <div className='footer-row-1-part-1'>
            <div className='quicklinks'>Quicklinks</div>
            <ul className='footer-row-1-part-1-list'>
              <li>
                <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('card-features')}> Card features </Nav.Link>
              </li>
              <li>     
                <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('product-overview')}>Overview</Nav.Link>
              </li>
              <li>              
                <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('howitworks')}>How it works</Nav.Link>
              </li>
              <li>              
                <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('benefits')}>Benefits</Nav.Link>
              </li>
              <li>             
                 <Nav.Link href="" className='nav-link' onClick={()=> scrollHandler('securetrusted')}>Security</Nav.Link> 
              </li>
            </ul>
          </div>
          <div className='footer-row-1-part-2'>
            <div className='contact-info'>Contact Info</div>
            <div className='email-phone-container'>
              <a href='mailto:customercare@paytaps.com' id='email_address'>Email: customercare@paytaps.com</a>  
              <a href='tel:+37052140176' id='phone_number'>Phone: +370 5 214 0176</a>  
            </div>
          </div>
        </div>
        <div className='footer-row-2'>
          <figure>
            <a href='/'><img src='/images/landingpage/footer/Layer 1.svg' alt='paytabs mini logo' draggable='false' /></a>  
          </figure>
          <ul>
            <li>
              <a href='https://www.linkedin.com/company/paytaps'><img src='/images/landingpage/footer/tabler_brand-linkedin.svg' alt='linkedin logo' draggable='false' /></a>  
            </li> 
            <li>
              <a href='https://www.facebook.com/people/Paytaps/61565936985315/'><img src='/images/landingpage/footer/tabler_brand-facebook.svg' alt='facebook logo' draggable='false' /></a>
            </li>
            <li>
              <a href='https://www.instagram.com/paytaps_card/'><img src='/images/landingpage/footer/63765483303970c874ba3961_ic_instagram.svg.svg' alt='instagram logo' draggable='false' /></a>
            </li>
            <li>
              <a href='http://x.com/Paytaps14305'><img src='/images/landingpage/footer/Link.svg' alt='x logo' draggable='false' /></a>  
            </li>
          </ul>
        </div>
        <hr />
        <div className='footer-row-3'> 
          <div className='footer-row-3-content'>Paytaps. © {new Date().getFullYear()}  All Rights Reserved.</div> 
          <ul className='legal-privacy-policy-terms-of-use'>
            <li><a href='/' className='footer-row-3-content'>Legal</a></li>
            <li><a href='/' className='footer-row-3-content'>Privacy Policy</a></li>
            <li><a href='/' className='footer-row-3-content'>Terms of Use</a></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default LpFooter
