import React, { useEffect, useRef, useState } from 'react'
import  IntlTelInput  from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";

import { intlTelInput } from "intl-tel-input/react"

const Phone = (props) => {
    
    const ref = useRef(false)
    const [isValid, setIsValid] = useState(null);
    const [number, setNumber] = useState(null);
    const [error, setError] = useState(null)
    const [errorMsg, setErrorMsg] = useState(null)
    let init = 0
    useEffect(()=>{
        
        document.querySelector('.iti__tel-input').addEventListener('keyup', ()=>{
                  setNumber(true)
                  if(props.intel.current?.getInstance().isValidNumber()) {
                    setError(null)
                  } else {
                    if(props.intel.current?.getInstance().getNumber()==='' && props.intel.current?.getInstance().getValidationError()===2) {
                      setErrorMsg('Required!')
                    } else if (props.intel.current?.getInstance().getValidationError()===2 && props.intel.current?.getInstance().getNumber()!=='') {
                      setErrorMsg('Too Short')
                    } else if(props.intel.current?.getInstance().getValidationError()===1) {
                      setErrorMsg('Invalid Country Code')
                    } else if(props.intel.current?.getInstance().getValidationError()===3) {
                      setErrorMsg('Too Long')
                    } else if(props.intel.current?.getInstance().getValidationError()===4) {
                      setErrorMsg('Is Possible Local Only')
                    } else if(props.intel.current?.getInstance().getValidationError()===5) {
                      setErrorMsg('Invalid Length')
                    }
                    props.intel.current?.getInstance().setNumber(props.intel.current?.getInstance().getNumber())
                    setError(true)
                    ref.current = true
                    props.handleDisable(props.intel.current?.getInstance().isValidNumber())
                  }
                  props.handleDisable(props.intel.current?.getInstance().isValidNumber())

                   
        })
          
    })
      
  return (
    <>
      
      <div className='form_control'>
        {props.label && <label htmlFor={props.id} className='form_label'>{props.label}</label>}
        <IntlTelInput
          initOptions={{
              initialCountry: "us",
              utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.8.1/build/js/utils.js",
              strictMode: true, 
              excludeCountries: ["af", "tn", "ag", "ar", "aw", "bs", "bd","bb","by","bz", "cm", "ky", "hr", "cd", "cg", "ec", "sv", "gi", "ht", "iq", "ir", "jm", "jo", "lb", "ml", "mz", 
                "pa", "ph", "ru", "sn", "ss", "lk", "sy", "ug", "vu", "vn", "vg", "vi", "ye", "dz", "ao", "bg", "bf", "ci", "ke", "mt", "mc", "na", "ng", "za", "sd", "tz", "ve"   
              ] ,   
              useFullscreenPopup: false,
              autoPlaceholder: 'aggressive',
              customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                return 'Enter Phone Number'
              },
              
          }}
          ref={props.intel}
        />
        {( ref.current && error) &&<div style={{fontSize: '12px',
                                                fontWeight: '500',
                                                lineHeight: '20px',
                                                color: 'red',
                                                paddingLeft: '10px'}}>
        {errorMsg}</div>}
      </div>
    </>
  )
}

export default Phone
